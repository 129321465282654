*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #18364a;
  text-align: left;
  /* background: linear-gradient(180deg, #1bb9ce 0%, #050b37 100%); */
  background-image: url(./assets/images/Pattern.svg) !important;
  /*background-repeat: no-repeat;*/
  background-attachment: fixed;
  height: 100%;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

a {
  text-decoration: none !important;
  color: #18364a;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

ul {
  list-style-type: none;
}

p {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  font-weight: 300;
}

.bg-white-container {
  background-color: #fff;
  max-width: fit-content;
  margin: 0 auto;
}

.ml-55 {
  margin-left: 55px;
}

.new-home .navbar-nav {
  position: static;
}

.new-home .navbar .sharetext {
  font-weight: 600;
  font-size: 12px;
  color: #18364a;
}

.new-home .bg-light-nav {
  background-color: #fff !important;
}

.bg-light-nav {
  background-color: #fff;
}

.new-home .navbar .shareless {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #18364a;
}

.new-home .navbar .greenles {
  font-weight: 300;
}

.new-home .navbar .redles {
  font-weight: 300;
}

.new-home .navbar .share-green {
  color: rgba(0, 182, 91);
  font-size: 14px;
}

.new-home .navbar .share-red {
  color: rgba(252, 69, 73);
  font-size: 14px;
}

.new-home .navbar .border-right {
  border-right: 0.5px dashed #bdbdbd !important;
  padding-right: 15px;
}

.new-home .navbar .border-r {
  border: 1px solid #e0e0e0;
  border-radius: 35px;
  padding: 0 7px;
  margin-left: -50px;
}

.pause {
  position: absolute;
  right: -10px;
  top: 20px;
  cursor: pointer;
  z-index: 7;
}

.new-home .navbar .login {
  font-weight: 600;
  font-size: 16px;
  color: #050b37;
  font-family: "Poppins", sans-serif !important;
  margin-right: 20px;
}

.new-home .navbar {
  width: 96%;
  margin: 0 auto;
}

.new-home .login-btn {
  border-radius: 40px;
  font-weight: bold;
  font-size: 14px;
  padding: 10px 29px;
  /* width: 115px; */
  background: #149ec3;
  color: #fff;
}

.new-home .nabvar2 {
  background: linear-gradient(180deg, #ffffff 0%, #f8f7f5 100%);
  width: 100%;
}

.new-home .nabvar2 .nav-item .nav-link {
  margin-right: 30px;
  color: #3f3f3f;
  font-size: 14px;
  font-weight: 600;
}

.new-home .nabvar2 .nav-item .nav-link:hover {
  color: #1bb9ce !important;
  border-bottom: 0px solid #f8921f;
}

.new-home .nabvar2 .nav-item .nav-link :last-child {
  border-bottom: 0;
}

.nav-item .active {
  color: #1bb9ce !important;
}

/* .dropdown-toggle:after {
    content: none !important;
} */
.dropdown-menu .dropdown-toggle:after {
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  content: none;
}

.dropdown-menu .dropdown-menu {
  margin-left: 0;
  margin-right: 0;
}

.nav-link .img-click {
  position: absolute;
  top: 7px;
  right: 0;
}

#myDIV {
  position: absolute;
  top: 0;
  right: 0;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  height: 400px;
  width: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: none;
}

.fontuser {
  position: relative;
  color: #050b37;
}

.fontuser i {
  position: absolute;
  /* right: 15px; */
  top: 27px;
  color: gray;
  font-size: 22px;
  font-weight: 600;
}

.line-top-top {
  background: #f8f8f8;
  height: 42px;
  margin-bottom: 12px;
}

.shift-margin {
  margin-left: 0px;
}

#h5-special {
  color: #149ec3;
  font-weight: 400;
  font-size: 14px;
  padding-top: 9px;
}

.news input[type="text"] {
  width: 90%;
  height: 40px;
  margin: 8px 0;
  outline: 0;
  color: #050b37;
  font-size: 14px;
  font-size: 14px;
  display: inline-block;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 21px;
  padding-left: 12px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.news input::placeholder {
  color: #050b37;
  font-size: 14px;
}

.new-home .news {
  background: #fff;
}

.dropdown-menu i {
  float: right;
  font-size: 16px;
  font-weight: 700;
  margin-top: 1px;
}

.dropdown-menu li a {
  position: relative;
  font-weight: 500;
  font-size: 12px;
  padding: 7px 26px;
  color: #18364a;
}

.header-cbw-market {
  margin-top: 6px;
  background: #f9f9f9;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.header-cbw-market li {
  margin: 10px 0px;
}

.caret-off::before {
  display: none;
}

.caret-off::after {
  display: none;
}

.dropdown-menu li a:hover {
  background: #18364a;
  color: #fff;
}

.nav-item .submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: 230px;
}

.nav-item .submenu-left {
  right: 100%;
  left: auto;
}

.dropdown-menu > li:hover {
  background-color: #e0e0e0;
}

/* .navbar-nav > li:hover > .dropdown-menu {
    display: block;
} */
.dropdown .fa-angle-right {
  border: 0px solid #f8921f;
}

.dropdown-menu > li:hover > .submenu {
  display: block;
  padding: 10px 0px !important;
}

.new-home .news {
  background: #fff;
}

.new-home .news .news-left-img {
  height: 45px;
  margin-right: 14px;
}

.new-home .news .border-news {
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  height: auto;
}

.new-home .news .more-details {
  border-bottom: 3px solid#F8921F;
  padding: 10px 27px;
  text-align: right;
}

.new-home .news .more-details a {
  color: #149ec3;
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline !important;
}

.new-home .news .line-top {
  border-top: 1px solid #f2f2f2;
}

.new-home .news .text-news {
  background: #f2f2f2;
  font-weight: bold;
  font-size: 16px;
  color: #050b37;
  text-align: center;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-home .news .pd {
  padding: 0 10px;
}

.new-home .news .news-content {
  font-weight: 400;
  font-size: 14px;
  color: #18364a;
}

.new-home .news .news-ago-text {
  color: #828282;
  font-size: 12px;
}

.new-home .news .news-head {
  padding: 5px 15px;
  overflow: scroll;
  height: 350px;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
}

.new-home .news .news-head::-webkit-scrollbar {
  display: none;
}

.rightNewsScroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 530px;
}

.new-home .news .line-br {
  border: 1px dashed #e0e0e0;
  width: 300px;
  margin-bottom: 15px;
}

.new-home .news .line2 {
  border: 1px dashed #e0e0e0;
  width: 100%;
  float: right;
  margin-bottom: 8px;
}

.new-home .news .breaking-new {
  background: #f2c94c;
  border-radius: 10px;
  /* width: 73%; */
  padding: 2px 17px;
  font-weight: bold;
  font-size: 10px;
}

.new-home .breaking-border img {
  border-radius: 4px;
}

.new-home .news .breaking-news-ago {
  font-weight: normal;
  font-size: 10px;
  color: #828282;
  text-align: right;
}

.new-home .news .news-heading {
  font-weight: 600;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.new-home .news .news-author {
  color: #3f3f3f;
  font-size: 12px;
  font-weight: 500;
  margin-right: 10px;
}

.new-home .line-border-section {
  background: #f2f2f2;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
  height: 9px;
}

.new-home .treding-videos {
  background: #fff;
  padding: 23px;
}

.new-home .treding-videos .trending-text {
  color: #050b37;
  font-weight: bold;
  font-size: 18px;
}

.new-home .orange-line {
  border: 4px solid #f8921f;
  width: 50px;
}

.new-home .treding-videos .video-title {
  font-weight: 600;
  font-size: 14px;
  width: 95%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.new-home .treding-videos .video-views {
  font-size: 12px;
  color: #9b9b9b;
}

.new-home .treding-videos img {
  width: 95%;
  height: 180px;
}

.new-home .breaking-news {
  background: #fff;
  padding: 23px;
}

.new-home .breaking-news .breaking-text {
  color: #050b37;
  font-weight: bold;
  font-size: 18px;
}

.new-home .breaking-news p {
  font-weight: 500;
  font-size: 16px;
  color: #18364a;
  margin-left: 9px;
}

.new-home .breaking-news span {
  font-size: 12px;
  color: #9b9b9b;
  padding-top: 20px;
  padding-left: 9px;
}

.new-home .breaking-news .breaking-border {
  border: 1px solid #f2f2f2;
  border-radius: 4px;
}

.new-home .breaking-border {
  max-width: 49% !important;
}

.new-home .breaking-border a {
  font-size: 15px;
  text-decoration: none !important;
  color: #18364a;
}

.new-home .break-right {
  margin-left: 20px;
}

.breaking-border .col-md-8 {
  max-width: 62.667% !important;
}

.new-home .break-border-main {
  border: 1px solid #f2f2f2;
  width: 100%;
  margin-top: 3%;
}

.new-home .more-article {
  text-align: right;
  margin-top: 3%;
}

.new-home .more-article .more-btn {
  border-radius: 40px;
  font-weight: bold;
  font-size: 12px;
  padding: 10px 25px;
  background: #149ec3;
  color: #ffffff;
  text-decoration: none;
}

.ccap-banner-slider .banner-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "overflow";
}

.ccap-banner-slider .banner-content .main_image_banner {
  grid-area: overflow;
}

.ccap-banner-slider .banner-content .banner-textover {
  grid-area: overflow;
  margin-top: 5%;
  text-align: left;
}

.ccap-banner-slider .banner-content .banner-textover h1 {
  font-weight: 600;
  font-size: 28px;
  color: #ffffff;
  text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  margin-left: 52px;
}

.ccap-banner-slider .banner-content .banner-textover h2 {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin-left: 52px;
  opacity: 0.8;
}

.ccap-banner-slider .read-demo {
  border-radius: 40px;
  font-weight: bold;
  font-size: 12px;
  padding: 11px 30px;
  background: #149ec3;
  color: #ffffff;
  margin-left: 46px;
  border: 0;
  margin-top: 3%;
}

.ccap-banner-slider {
  margin-right: -15px;
  margin-left: -15px;
}

.ccap-banner-slider .carousel-indicators li {
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.new-home .editor-choice {
  background: #fff;
  padding: 23px;
}

.new-home .editor-choice .editor-text {
  color: #050b37;
  font-weight: bold;
  font-size: 18px;
}

.new-home .editor-choice .editor-column p {
  font-weight: 600;
  font-size: 12px;
  color: #505762;
}

.new-home .editor-choice .editor-column h4 {
  font-weight: 500;
  font-size: 14px;
  color: #18364a;
}

.new-home .editor-choice .editor-column span {
  font-weight: 400;
  font-size: 12px;
  color: #9b9b9b;
  padding-top: 12px;
}

.new-home .editor-choice .editor-column {
  border-right: 1px solid #e0e0e0;
}

.new-home .editor-choice .editor-column:last-child {
  border-right: 0px solid #e0e0e0;
}

.new-home .editor-choice .break-border-main {
  border: 1px solid #f2f2f2;
  width: 100%;
  margin-top: 0;
}

.new-home .Hot-stories {
  background: #fff;
  padding: 23px;
}

.new-home .Hot-stories .hot-text {
  color: #050b37;
  font-weight: bold;
  font-size: 18px;
}

.new-home .Hot-stories p {
  font-weight: 600;
  font-size: 12px;
  color: #505762;
  margin-top: 22px;
  margin-left: 6px;
}

.new-home .Hot-stories h4 {
  font-weight: 500;
  font-size: 14px;
  margin-left: 6px;
  color: #18364a;
}

.new-home .Hot-stories span {
  font-weight: 400;
  font-size: 12px;
  margin-left: 6px;
  color: #9b9b9b;
  padding-top: 12px;
}

.new-home .Hot-stories .hot-column img {
  width: 200px;
  border-radius: 15px;
  margin-bottom: 17px;
}

.new-home .Hot-stories .col-md-2 {
  flex: 0 0 20.667%;
  max-width: 20%;
}

.new-home .banner-cbw {
  background: #fff;
  padding: 23px;
}

.new-home .banner-cbw img {
  width: 525px;
}

.new-home .press-release {
  background: #fff;
  padding: 23px;
}

.new-home .press-release .press-text {
  color: #050b37;
  font-weight: bold;
  font-size: 18px;
}

.new-home .press-release p {
  font-weight: 500;
  font-size: 12px;
  color: #3a3c3d;
}

.new-home .press-release h4 {
  font-weight: 500;
  font-size: 14px;
  color: #18364a;
}

.new-home .press-release span {
  font-weight: 400;
  font-size: 12px;
  margin-left: 6px;
  color: #9b9b9b;
  padding-top: 12px;
}

.new-home .press-border {
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  max-width: 32% !important;
}

.new-home .press-right {
  margin-left: 20px;
}

.new-home .pressScroll {
  overflow: scroll;
  height: 1430px;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
}

.new-home .ea-pressScroll {
  overflow: scroll;
  /* height: 1430px; */
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
}

.new-home .pressScroll::-webkit-scrollbar {
  display: none;
}

.new-home .recomm-news {
  background: #fff;
  padding: 23px;
}

.new-home .recomm-news .recomm-text {
  color: #050b37;
  font-weight: bold;
  font-size: 18px;
}

.new-home .recomm-news p {
  font-weight: 500;
  font-size: 12px;
  color: #3a3c3d;
}

.new-home .recomm-news h4 {
  font-weight: 500;
  font-size: 14px;
  color: #18364a;
}

.new-home .recomm-news span {
  font-weight: 400;
  font-size: 12px;
  margin-left: 6px;
  color: #9b9b9b;
  padding-top: 12px;
}

.new-home .recomm-border {
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  max-width: 32% !important;
}

.new-home .recomm-right {
  margin-left: 20px;
}

.new-home .partner-our {
  background: #fff;
  padding: 23px;
}

.new-home .partner-our .partner-text {
  color: #050b37;
  font-weight: bold;
  font-size: 18px;
}

.new-home .partner-our img {
  width: 200px;
  margin: 0 27px;
}

.new-home .partner-our .orange-line1 {
  border: 4px solid #f8921f;
  width: 50px;
  margin: 0 auto;
}

.new-home .twiiter-feed {
  background: #fff;
  padding: 23px;
}

.new-home .twiiter-feed h4 {
  font-weight: 500;
  font-size: 14px;
  color: #18364a;
  margin-right: 20%;
}

.new-home .twiiter-feed p {
  font-weight: 500;
  font-size: 12px;
  color: #18364a;
  margin-top: 11px;
}

.new-home .twiiter-feed span {
  font-weight: 500;
  font-size: 12px;
  color: #9b9b9b;
}

.new-home .twiiter-feed a {
  font-size: 12px;
  color: #337ab7;
}

.new-home .twiiter-feed .twitter-bird {
  position: relative;
  top: 10px;
  left: 12px;
}

.new-home .twiiter-feed .cbw-twi {
  height: 455px;
}

.new-home .car-cbw-tab {
  background: #f2f2f2;
  width: 100%;
  height: 47px;
  padding-top: 5px;
}

.new-home .border-news .nav-tabs {
  border-bottom: 0px solid #dee2e6;
  background: #e0e0e0;
  position: relative;
  height: 36px;
  margin-bottom: 10px;
  width: 90%;
  margin-left: 12px;
  border-radius: 25px;
}

.new-home .border-news .nav-tabs li .active {
  background: #149ec3;
  border-radius: 19px;
  color: #fff;
  padding: 8px 10px;
}

.new-home .border-news .nav-tabs li:first-child {
  margin-left: 1px;
  margin-top: 1px;
}

#Trending {
  position: absolute;
  left: 3px;
}

#Analysis {
  position: absolute;
  right: 0px;
  padding-left: 22px;
  padding-right: 24px;
  top: 0;
}

.new-home .border-news .nav-tabs li a {
  font-weight: bold;
  font-size: 12px;
  color: #828282;
  text-align: center;
  /* padding: 7px; */
  margin-top: 0px;
  /* display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none; */
  border-radius: 19px;
}

.ngen-search {
  padding: 0px 0px 0px;
}

.form-search-input {
  width: 0px;
  height: 55px;
  border: 0;
  outline: 0;
  font-size: 21px;
  padding: 0px 0px 0px 0px;
  color: #151515;
  transition: all 0.5s;
}

.search-input-open {
  width: 410px !important;
  padding: 0px 0px 0px 20px !important;
  display: initial !important;
}

.form-search-submit {
  display: inline-block;
  width: 55px;
  height: 43px;
  border: 0;
  outline: 0;
  font-size: 21px;
  color: white;
  cursor: pointer;
  text-align: center;
}

.home-carousel .carousel-indicators {
  display: none;
}

.home-carousel .banner-content .banner-textover h1 {
  font-weight: 600;
  font-size: 14px;
  color: #ffdd74;
}

.home-carousel .banner-content .banner-textover h5 {
  font-weight: 300;
  font-size: 12px;
  color: #f9f9f9;
}

.home-carousel .banner-content .banner-textover .write-us-btn {
  font-weight: 600;
  font-size: 11px;
  float: right;
  color: #ffffff;
  text-decoration: none;
  background: #18364a;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  padding: 7px 20px;
  /*margin-top: 32%;*/
  margin-top: 18%;
  margin-right: 3%;
}

.home-carousel .banner-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "overflow";
}

.home-carousel .banner-content .main_image_banner {
  grid-area: overflow;
}

.home-carousel .banner-content .banner-textover {
  grid-area: overflow;
  margin-top: 10%;
  margin-left: 5%;
}

.home-spacing {
  margin-top: 20px;
  margin-bottom: 20px;
}

/********Footer Start section*********/
.ccp-footer .ccap-footer-middle {
  background: #333333;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
  height: auto;
  width: auto;
}

.ccp-footer .ccap-footer-middle .footer-logo {
  margin-top: 25px;
  margin-bottom: 15px;
}

.ccap-footer-middle .particular-left {
  margin-left: 5%;
}

.ccp-footer .ccap-footer-middle h5 {
  color: #c9cdd1;
  font-size: 14px;
  text-align: center;
  margin-top: 26px;
  font-weight: 300;
  margin-bottom: 6%;
}

.ccap-footer-middle .footer-adressing img {
  width: 43px;
  height: 67px;
}

.ccap-footer-middle .footer-env {
  float: left;
  border-right: 1px solid #4f4f4f;
  padding-right: 15px;
  margin-right: 15px;
}

.ccap-footer-middle .ccap-text {
  color: #afaeae;
  font-size: 12px;
  display: block;
  margin-top: 7px;
}

.ccap-footer-middle .ccap-text small {
  font-size: 11px;
  color: #f2f2f2;
  opacity: 0.8;
}

.ccap-footer-middle .custom-ccap {
  color: #f2f2f2 !important;
}

.ccap-footer-middle .envenv {
  margin-top: 6px;
  border: 1.5px solid #bdbdbd;
  border-radius: 15px;
  border-radius: 18px;
  padding: 5px 10px;
  font-weight: 400;
  font-size: 12px;
}

.footer-lower-side {
  background: #333333;
  padding-bottom: 29px;
  padding-top: 29px;
}

.footer-lower-side .social-icon {
  height: 32px;
  margin: 4px;
}

.footer-lower-side .spanblue {
  /* color: #14b4ca; */
  color: #f2f2f2;
  font-weight: 400;
  font-size: 12px;
}

.footer-lower-side .spanwhite {
  /* color: #fff; */
  color: #f2f2f2;
  font-weight: 300;
  font-size: 12px;
}

.footer-lower-side .ccap-copyright {
  margin-top: 7px;
}

.footer-lower-side .span-privacy {
  color: #149ec3 !important;
}

.footer-lower-side .span-privacy {
  color: #149ec3;
}

.footer-lower-side .ccap-privacy a {
  color: #f2f2f2;
  font-weight: 400;
  font-size: 12px;
  text-decoration: none;
  opacity: 0.9;
}

.footer-lower-side .ccap-line {
  color: #f2f2f2;
  font-size: 13px;
  opacity: 0.8;
}

#img-path {
  width: 18px;
  height: 18px;
}

footer .break-border-main-footer {
  width: 100%;
  margin-top: 3%;
  border: 1px solid #4f4f4f;
}

/********Footer End section*********/
/********Press-release Start section*********/
.press-release-main .banner-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "overflow";
}

.ppress-release-main .banner-content .main_image_banner {
  grid-area: overflow;
}

.press-release-main .banner-content .banner-textover {
  grid-area: overflow;
}

.press-release-main .banner-content .banner-textover h1 {
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  color: #f2c94c;
  text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  margin-top: 30px;
  margin-left: -3%;
}

.press-release-main .banner-content .banner-textover h5 {
  font-weight: 500;
  font-size: 25px;
  text-align: center;
  color: #fff;
  margin-top: 12px;
  margin-left: 14%;
}

.press-news {
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;
}

.press-news h1 {
  font-weight: 600;
  background: #f2f2f2;
  font-size: 16px;
  color: #050b37;
  text-align: center;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-menu-shape {
  border: 0.5px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 5px;
  /* width: 110%;*/
}

.input-menu-shape input ::placeholder {
  color: #666666;
  font-size: 12px;
}

.input-menu-shape #dropdownMenuButton1 {
  width: 100%;
  border: 0px solid #ced4da;
  font-size: 12px;
  color: #666666;
  height: auto;
  text-align: left;
}

.input-menu-shape #dropdownMenuButton {
  width: 100%;
  border: 0px solid #ced4da;
  font-size: 12px;
  color: #666666;
  text-align: left;
}

.join_btnn1 {
  color: #ffffff !important;
  border: none;
  border-radius: 50px;
  width: 100%;
  height: 100%;
  clear: both;
  font-size: 12px;
  font-weight: 700;
  background-color: #149ec3;
}

.serachcss1 {
  font-size: 12px;
  color: #666666;
}

.reset1 {
  text-decoration: none;
  color: #2f343a;
  font-size: 13px;
  font-weight: 700;
  float: right;
}

.right-banner-press h6 {
  font-weight: 600;
  font-size: 14px;
  color: #050b37;
  margin-top: 12px;
  margin-left: 4px;
  margin-bottom: 5px;
}

.right-banner-press p {
  font-weight: 300;
  font-size: 12px;
  color: #4f4f4f;
  margin-top: 12px;
  margin-left: 4px;
  margin-bottom: 5px;
}

.line-border-section1 {
  background: #f2f2f2;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
  height: 10px;
  width: 100%;
  margin: 0;
}

.press-rel-col p {
  font-weight: 400;
  font-size: 12px;
  color: #4f4f4f;
}

.press-rel-col a:hover {
  color: #149ec3 !important;
}

.press-rel-col h4 {
  font-weight: 600;
  font-size: 14px;
  color: #18364a;
}

.press-rel-col span {
  font-weight: 400;
  font-size: 12px;
  color: #9b9b9b;
}

.press-rel-col img {
  border-radius: 4px;
}

.press-rel-col span img {
  margin-top: -3px;
  margin-right: 5px;
  margin-left: 5px;
}

.border-pres {
  border: 1px solid #e0e0e0;
  width: 100%;
}

#dropdownMenuLink {
  font-size: 12px;
  color: #505762;
  display: block;
  width: 100px !important;
  padding: 7px;
}

.press-para-n p {
  font-size: 12px;
  color: #9b9b9b;
  margin-top: 7px;
  margin-left: -20px;
}

.dropdown-prss {
  border: 0.75px solid #149ec3;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
}

.dropdown-prss:focus {
  border: #149ec3;
}

.dropdown-prss .fa-angle-left {
  color: #fff;
  font-size: 22px;
  margin-right: 35px;
  border: 0px solid #f8921f;
  border-radius: 50%;
  padding: 4px 14px 4px 11px;
  background: #1bb4dd;
}

#dropdownMenuLink .fa-angle-down {
  float: right;
  position: relative;
  top: 7px;
  font-size: 16px;
  right: 5px;
}

.dropdown-prss11 {
  font-weight: 400;
  font-size: 12px;
  color: #9b9b9b;
}

.pagination-press {
  float: right;
  /*position: absolute;
    right: 35px;*/
}

.page-release {
  padding: 6px 12px;
  border: 0.5px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  color: #505762;
}

.page-release3 {
  margin-right: 18px;
  margin-left: 18px;
  margin-top: 8px;
  font-size: 12px;
  color: #505762;
}

.page-release1 .fa-angle-left,
.page-release1 .fa-angle-right {
  color: #fff;
  font-size: 25px;
  margin-right: -5px;
  border: 0px solid #f8921f;
  border-radius: 50%;
  padding: 3px 13px 0px 8px;
  background: #1bb4dd;
  width: 35px;
  height: 35px;
}

.page-release3 .fa-angle-left:hover,
.page-release3 .fa-angle-right :hover {
  background: #1bb4dd;
  opacity: 0.5;
}

.full1 {
  width: 100%;
  height: 230px;
  border-radius: 4px;
}

.press-rel-col a:hover {
  color: #1bb9ce;
  text-decoration: none;
}

.input-menu-shape .dropdown-toggle::after {
  margin-left: 16px;
  float: right;
  margin-top: 9px;
  font-size: 16px;
}

/* .press-content {
  margin-top: -1px;
} */
.press-content ::-webkit-input-placeholder {
  font-size: 12px;
  color: #666666;
  height: auto;
  text-align: left;
}

.press-content ::-moz-placeholder {
  font-size: 12px;
  color: #666666;
  height: auto;
  text-align: left;
}

.press-content :-ms-input-placeholder {
  font-size: 12px;
  color: #666666;
  height: auto;
  text-align: left;
}

.press-content ::placeholder {
  font-size: 12px;
  color: #666666;
  height: auto;
  text-align: left;
}

#datetimepicker1 {
  border: 0;
  outline: 0;
  width: 110%;
}

/********Press-release End section*********/
/********Press-release_details  Start section*********/
.press-detail-section h2 {
  font-weight: 600;
  font-size: 12px;
  color: #828282;
}

.press-detail-section h3 {
  font-size: 12px;
  line-height: 18px;
  color: #9b9b9b;
}

.press-re-del h1 {
  font-weight: 600;
  font-size: 18px;
  color: #18364a;
}

.press-detail-section1 h3 {
  font-weight: 400;
  font-size: 12px;
  color: #505762;
}

.press-rel-col1 .press-img-detial {
  width: 100%;
  border-radius: 4px;
  margin-top: 0px;
  margin-bottom: 12px;
}

.press-rel-col1 p {
  font-weight: 400;
  font-size: 14px;
  color: #18364a;
  /* margin-top: 12px; */
  margin-bottom: 12px;
}

.press-rel-col1 .press-blue {
  font-weight: 500;
  font-size: 12px;
  color: #337ab7;
}

.press-rel-col1 .press-blue1 {
  font-weight: 400;
  font-size: 12px;
  color: #337ab7;
}

.press-rel-col1 .press-ul {
  list-style-type: none;
  margin-left: -42px;
}

.press-rel-col1 .press-li {
  display: inline-block;
}

.press-detail-section {
  margin-top: -6px;
}

.press-detail-section1 h3 {
  font-weight: 500;
  font-size: 12px;
  color: #18364a;
}

.margin-spacing {
  margin-top: 5%;
}

.press-detail-section1 p {
  font-weight: 400;
  font-size: 10px;
  color: #828282;
  margin-top: -6px;
}

.press-detail-section1 span {
  font-weight: 600;
  font-size: 8px;
  color: #828282;
  border: 1px solid #828282;
  border-radius: 8px;
  margin-left: 8px;
  padding: 2px 9px;
  text-transform: uppercase;
}

.press-detail-section1 span a {
  font-weight: 600;
  font-size: 8px;
  text-decoration: none;
  padding: 2px 9px;
  text-transform: uppercase;
  cursor: pointer;
}

.press-rel-para .press-ul {
  list-style-type: none;
  margin-left: 0px;
  float: right;
  margin-top: 12px;
}

.press-rel-para .press-li {
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  color: #4f4f4f;
}

.press-rel-para p {
  font-weight: 400;
  font-size: 12px;
  color: #4f4f4f;
  clear: both;
}

.press-rel-para span img {
  position: relative;
  top: -1px;
  left: -1px;
}

.max-press p {
  font-weight: 400;
  font-size: 10px;
  text-align: right;
  color: #828282;
}

.max-press ::-webkit-input-placeholder {
  font-weight: 300;
  font-size: 12px;
  color: #9b9b9b;
}

.max-press ::-moz-placeholder {
  font-weight: 300;
  font-size: 12px;
  color: #9b9b9b;
}

.max-press :-ms-input-placeholder {
  font-weight: 300;
  font-size: 12px;
  color: #9b9b9b;
}

.max-press ::placeholder {
  font-weight: 300;
  font-size: 12px;
  color: #9b9b9b;
}

.max-press .text-close {
  position: relative;
  top: 24px;
  right: 8px;
  float: right;
}

.max-press .telegram {
  position: relative;
  bottom: 28px;
  right: 4px;
  float: right;
}

.max-press {
  margin-top: -29px;
}

.press-detail-section-press h3 {
  font-weight: 500;
  font-size: 12px;
  color: #18364a;
}

.press-detail-section-press p {
  font-weight: 400;
  font-size: 10px;
  color: #828282;
  margin-top: -6px;
}

.press-detail-section-press .pres-demo-text p {
  font-weight: 400;
  font-size: 12px;
  color: #505762;
}

.press-detail-section-press .pres-demo-text img {
  margin-right: 6px;
}

.press-detail-section-press .pres-demo-text .border-pres {
  border: 1px solid #f2f2f2;
  width: 100%;
}

.learn-more-sidenav {
  font-weight: 600;
  font-size: 12px;
  text-decoration: underline;
  text-transform: uppercase;
  color: #337ab7;
  margin-left: 18px;
  cursor: pointer;
}

.press-detail-section-sidenav h3 {
  font-weight: 500;
  font-size: 12px;
  color: #18364a;
  margin-left: 12px;
}

.press-detail-section-sidenav p {
  font-weight: 400;
  font-size: 10px;
  color: #828282;
  margin-top: -6px;
  margin-left: 12px;
}

.press-detail-section-sidenav .pres-demo-text p {
  font-weight: 400;
  font-size: 12px;
  color: #505762;
}

.press-detail-section-sidenav .pres-demo-text img {
  margin-right: 6px;
}

.press-detail-section-sidenav .pres-demo-text .border-pres {
  border: 1px solid #f2f2f2;
  width: 100%;
}

.anchore-sidenav {
  background: #505762;
  margin-bottom: -47px;
}

.press-detail-section-sidenav {
  background: #f9f9f9;
  height: 206px;
  padding-top: 16px;
  margin-bottom: 20px;
}

.press-detail-section-sidenav .text-close {
  position: relative;
  top: 40px;
  right: 3px;
  float: right;
}

.press-detail-section-sidenav .telegram {
  position: relative;
  bottom: 44px;
  right: 0px;
  float: right;
}

.sidenav1 ::-webkit-input-placeholder {
  font-weight: 300;
  font-size: 12px;
  color: #9b9b9b;
}

.sidenav1 ::-moz-placeholder {
  font-weight: 300;
  font-size: 12px;
  color: #9b9b9b;
}

.sidenav1 :-ms-input-placeholder {
  font-weight: 300;
  font-size: 12px;
  color: #9b9b9b;
}

.sidenav1 ::placeholder {
  font-weight: 300;
  font-size: 12px;
  color: #9b9b9b;
}

.sidenav1 {
  margin-top: -35px;
}

.character-cls {
  margin-top: -49px !important;
  margin-right: 12px;
  clear: both;
}

.user-margin {
  margin-left: 12px;
}

/********Press-release_details End section*********/
/* The side navigation menu */
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  background: #ffffff;
  box-shadow: -6px 0px 8px rgba(0, 0, 0, 0.05);
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.5s;
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover,
.sidenav .closebtn :hover {
  color: #505762;
}

.sidenav .closebtn {
  position: absolute;
  top: -6px;
  right: 6px;
  font-size: 25px;
}

.hot-stories {
  font-weight: 600;
  font-size: 12px;
  text-align: right;
  text-decoration: underline;
  color: #149ec3;
}

.hot-store .write-us-btn {
  font-weight: 600;
  font-size: 12px;
  color: #149ec3;
  border: 2px solid #149ec3;
  text-decoration: none;
  background: #ffffff;
  border-radius: 25px;
  padding: 10px 35px;
}

.hot-store {
  text-align: center;
}

.news-spacing {
  margin-top: 20px;
  margin-bottom: 20px;
}

.news-details-page {
  list-style-type: none;
  margin-top: 7px;
  margin-left: -34px;
  margin-bottom: 6px;
}

.news-details-page li {
  display: inline-block;
  background: #f9f9f9;
  border-radius: 17px;
  border-radius: 17px;
  padding: 2px 20px;
}

.news-details-page li:first-child {
  margin-bottom: 12px;
}

.news-details-page li:hover {
  background: #ffdb6d;
  border-radius: 17px;
}

.news-details-page .active {
  background: #ffdb6d;
  border-radius: 17px;
}

.news-details-page a {
  font-weight: 600;
  font-size: 10px;
  color: #18364a;
  text-decoration: none;
}

.border-news {
  border: 1px solid #e0e0e0;
  width: 100%;
}

.news-bac-white {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}

.news-bac-white ::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 12px;
  opacity: 0.5;
}

.news-bac-white ::-moz-placeholder {
  font-weight: 400;
  font-size: 12px;
  opacity: 0.5;
}

.news-bac-white :-ms-input-placeholder {
  font-weight: 400;
  font-size: 12px;
  opacity: 0.5;
}

.news-bac-white ::placeholder {
  font-weight: 400;
  font-size: 12px;
  opacity: 0.5;
}

.news-laptop .banner-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "overflow";
}

.news-laptop .banner-content .main_image_banner {
  grid-area: overflow;
}

.news-laptop .banner-content .banner-textover {
  grid-area: overflow;
  margin-top: -6%;
  margin-left: 8%;
  margin-right: 5%;
  margin-bottom: 5%;
  padding-bottom: 38px;
}

.news-bac-white h3 {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #18364a;
  margin-top: 25px;
  margin-left: 18%;
  margin-right: 18%;
  margin-bottom: 26px;
  line-height: 19px;
}

.news-bac-white .news-email {
  border: 0.75px solid #bdbdbd;
  border-radius: 4px;
  margin: 0% 2% 7%;
  outline: none;
}

.sub-now-btn {
  font-weight: 600;
  font-size: 12px;
  background: #b8e2ed;
  border-radius: 40px;
  text-decoration: none !important;
  color: #ffffff !important;
  padding: 10px 13px;
  text-transform: uppercase;
  margin-top: 5%;
}

.custom_inp {
  border: 0.75px solid #bdbdbd;
  width: 100px;
  margin: 0% 11% 12%;
  border-radius: 4px;
  clear: both;
  outline: none;
  height: 35px;
}

.news-bac-white1 h3 {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #fff;
  margin-top: 25px;
  margin-left: 9%;
  margin-right: 9%;
  margin-bottom: 28%;
  line-height: 27px;
}

.sub-now-btn1 {
  font-weight: 600;
  font-size: 12px;
  background: #fff;
  border-radius: 40px;
  text-decoration: none !important;
  color: #149ec3;
  padding: 10px 35px;
  text-transform: uppercase;
}

.sub-now-btn2 {
  font-weight: 600;
  font-size: 12px;
  background: #fff;
  border-radius: 40px;
  text-decoration: none !important;
  color: #149ec3;
  padding: 10px 35px;
  text-transform: uppercase;
  margin-left: 31%;
}

.video-bit-sign {
  border-radius: 4px;
  height: 300px;
}

.table-video-resp {
  height: 400px;
}

.table-video-resp .thead-dark {
  background: #050b37;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}

.table-video-resp td {
  font-weight: 600;
  font-size: 12px;
  color: #18364a;
  text-align: left;
  width: 130px;
  /* height: 145px; */
}

.table-video-resp table {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
}

.table-video-resp table td {
  border-top: 0px solid #dee2e6;
}

.table-video-resp table tr {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.table-video-resp .bit-span {
  font-weight: 300;
  font-size: 10px;
  color: #505762;
  margin-left: 43px;
  margin-top: -7px;
}

.table-video-resp .bit-price-img {
  width: 70%;
}

.table-video-resp .bit-price {
  font-weight: 600;
  font-size: 14px;
  text-align: right;
  color: #18364a;
  width: 66px;
}

.table-video-resp .bit-green {
  font-weight: 600;
  font-size: 10px;
  text-align: right;
  color: #27ae60;
}

.table-video-resp .bit-red {
  font-weight: 600;
  font-size: 10px;
  text-align: right;
  color: #eb5757;
}

.video-rel-col .img_video {
  border-radius: 4px;
}

.vide-detail-market {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
}

.vide-detail-market h2 {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  background: #050b37;
  height: 40px;
  border-radius: 4px;
  padding: 10px 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.vide-detail-market ul {
  list-style-type: none;
  display: inline-block;
  margin-left: -21px;
}

.vide-detail-market ul li {
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  padding: 3px 9px;
  color: #18364a;
}

.vide-detail-market ul li select {
  border: 0;
}

.vide-detail-market-mid {
  margin-left: 0px;
  margin-top: 12px;
}

.vide-detail-market-mid h3 {
  font-weight: 600;
  font-size: 14px;
  color: #18364a;
}

.vide-detail-market-mid p {
  font-weight: 600;
  font-size: 10px;
  color: #bdbdbd;
  margin-top: -7px;
}

.vide-detail-market ul .active {
  background: #b8e2ed;
  border-radius: 4px;
}

.vide-detail-market .market-mid {
  margin-left: -22px;
  margin-top: 5px;
}

.video-rel-col p {
  font-weight: 400;
  font-size: 10px;
  color: #9b9b9b;
  margin-top: -8px;
  margin-top: 12px;
}

.video-rel-col h4 {
  font-weight: 600;
  font-size: 14px;
  color: #050b37;
  margin-right: 20px;
  line-height: 19px;
}

.bit-price-mid h1 {
  font-weight: 600;
  font-size: 20px;
  color: #18364a;
  margin-left: 8%;
  margin-top: 5%;
  margin-bottom: 10px;
}

.bit-price-mid .bit-green {
  font-weight: 600;
  font-size: 13px;
  color: #27ae60;
}

.video-rel-col a {
  text-decoration: none;
}

.video-rel-col span {
  font-weight: 400;
  font-size: 10px;
  color: #9b9b9b;
  /* margin-top: 12px; */
}

.video-rel-col h4 {
  font-weight: 600;
  font-size: 14px;
  color: #050b37;
  margin-right: 20px;
  line-height: 19px;
}

.video-rel-col a {
  text-decoration: none;
}

.video-rel-col span {
  font-weight: 400;
  font-size: 10px;
  color: #9b9b9b;
  margin-bottom: 12px;
  margin-left: 0;
}

.video-rel-col {
  border-right: 1px solid #e0e0e0;
}

.video-rel-col:last-child {
  border-right: 0px solid #e0e0e0;
}

.video-rel-col .span-span {
  margin-right: 12px;
}

.video-rel-col .like_10 {
  margin-top: -2px;
  margin-right: 3px;
}

.video-rel-col .video-detail-img {
  width: 140px;
}

.video-rel-col h5 {
  font-weight: 600;
  font-size: 12px;
  color: #050b37;
  margin-right: 0px;
  line-height: 19px;
}

.learn-more-more {
  font-weight: 600;
  font-size: 12px;
  text-decoration: underline !important;
  text-transform: uppercase;
  color: #337ab7;
  margin-left: 0px;
  margin-top: 20px;
  cursor: pointer;
}

.input-menu-shape #dropdownMenuButton2 {
  width: 100%;
  border: 0px solid #ced4da;
  font-size: 12px;
  color: #666666;
  height: auto;
  text-align: left;
}

.external-analyst h4 {
  font-weight: 600;
  font-size: 14px;
  color: #149ec3;
}

.ea-list-acn {
  font-weight: 600;
  font-size: 14px;
  color: #149ec3;
}

.external-analyst h2 {
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #18364a;
  margin-bottom: 25px;
}

.external-analyst .city-para {
  font-weight: 400;
  font-size: 10px;
  text-align: center;
  color: #9b9b9b;
  margin-bottom: 10px;
}

.external-analyst .rate {
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #18364a;
  padding-bottom: 0px;
}

.external-analyst .txt-ext {
  font-weight: 400;
  font-size: 12px;
  color: #505762;
  margin-top: 10px;
  margin-right: 29px;
  margin-left: 28px;
}

.external-analyst .ext-body {
  background: #fcfcfc;
  border-radius: 4px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.external-analyst {
  border-right: 1px solid #e0e0e0;
  margin-bottom: 30px;
}

.external-analyst #myBtn {
  color: #149ec3;
  cursor: pointer;
}

#myLogin .modal-body {
  background-image: url(/src/assets/images/Pattern.svg);
  width: 777px !important;
  height: 535px !important;
}

#myLogin .login-screen {
  width: 435px;
  height: 535px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  margin-top: -15px !important;
  border-radius: 0px;
  margin-right: auto;
  margin-left: auto;
}

#myLogin .login-logo {
  width: 135px;
  height: 60px;
  margin-top: 30px;
  margin-bottom: 30px;
}

#myLogin h2 {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #18364a;
  margin-bottom: 30px;
}

#myLogin .border-lable-flt input:focus + label {
  color: #18364a;
  font-size: 12px;
}

#myLogin .border-lable-flt .form-control:placeholder-shown:not(:focus) + * {
  font-size: 12px;
  opacity: 0.3;
  top: 12px;
  left: 12px;
}

#myLogin .login-screen .login-input {
  width: 285px;
  height: 35px;
  opacity: 0.25;
  border-radius: 6px !important;
  border: solid 1px #444444;
  margin-left: 0px;
  text-align: left;
  background-color: #ffffff;
  margin-bottom: 10px;
  font-size: 12px;
}

#myLogin .focus-input:focus {
  box-shadow: 0 0 0 0 !important;
  font-size: 13px;
  font-weight: 600;
  border: solid 1px #18364a;
  text-align: left;
  color: #18364a;
  opacity: 0.9 !important;
}

#myLogin .login-button {
  margin-top: 33px;
  margin-bottom: 22px;
}

#myLogin .login-us-btn {
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  text-decoration: none;
  background: #149ec3;
  border-radius: 40px;
  padding: 10px 25%;
}

#myLogin .login-button-joinus {
  margin-bottom: 22px;
}

#myLogin .login-button-joinus .analyst-us-btn {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  border: 2px solid #149ec3;
  border-radius: 40px;
  color: #149ec3;
  padding: 7px 15%;
}

#myLogin .login-button-reader {
  margin-bottom: 22px;
}

#myLogin .login-button-reader .readder-us-btn {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  border: 2px solid #149ec3;
  border-radius: 40px;
  color: #149ec3;
  padding: 7px 15%;
}

#myLogin .input-group .border-lable-flt {
  display: table-cell;
  margin: 0 auto;
}

#myLogin .login-screen .login-border {
  text-align: center;
  border: 1px solid #e0e0e0;
  width: 329px;
  margin-left: 0px;
  margin: 0 auto;
  opacity: 0.3;
  margin-top: 12px;
}

#myLogin .login-screen .input-group {
  margin-bottom: 18px;
}

#myLogin .login-screen .login-input1 {
  width: 285px;
  height: 35px;
  opacity: 0.25;
  border-radius: 6px !important;
  border: solid 1px #444444;
  margin-left: 0px;
  text-align: left;
  background-color: #ffffff;
  margin-bottom: 10px;
  font-size: 12px;
}

#myLogin .login-screen .checkbox-hme {
  margin-left: 15px !important;
  float: left;
  margin-top: 25px !important;
}

#myLogin .login-screen .forgot-psd {
  float: right;
  font-size: 12px;
  text-align: left;
  color: #337ab7 !important;
  margin-top: 28px;
  font-weight: 500;
  margin-left: 0px;
  text-decoration: underline;
}

#myLogin .forgrt-home {
  float: right;
  margin-right: 30px;
}

#myLogin .login-screen .margintop {
  margin-top: 0px;
}

#myLogin .modal-dialog {
  right: 7% !important;
}

#white {
  color: #828282;
}

.external-analyst-ul ul {
  list-style-type: none;
  border: 1px solid #149ec3;
  box-sizing: border-box;
  border-radius: 4px;
  width: 230px;
  margin-bottom: 18px;
}

.external-analyst-ul li {
  padding: 8px 11px;
  font-weight: 400;
  font-size: 12px;
}

.external-analyst-ul li a {
  color: #149ec3;
}

.external-analyst-ul .active a {
  color: #ffffff;
  background: #149ec3;
  padding: 9px 11px;
}

.external-analyst-block .input-group-append {
  margin-left: -1px;
  height: 35px;
}

.external-analyst-block {
  position: relative;
  left: 43%;
}

.external-analyst-block {
  position: relative;
  left: 43%;
}

#external-video h3,
#external-video1 h3 {
  font-weight: 400;
  font-size: 14px;
  color: #4f4f4f;
  margin-bottom: 12px;
  margin-top: 10px;
}

#external-video h6,
#external-video1 h6 {
  font-weight: 400;
  font-size: 12px;
  color: #4f4f4f;
  margin-bottom: 12px;
  margin-left: 3px;
}

#external-video .image-respo,
#external-video1 .image-respo {
  width: 100%;
}

#external-video .video-rel-col h4,
#external-video1 .video-rel-col h4 {
  font-weight: 600;
  font-size: 14px;
  color: #050b37;
  margin-right: 0px;
  line-height: 19px;
  margin-top: 9px;
  margin-left: 3px;
  clear: both;
}

#external-video1 p .span-special {
  font-weight: 600;
  font-size: 12px;
  color: #505762;
}

#external-video1 .video-rel-col,
#external-video .video-rel-col {
  border-right: 0px solid #e0e0e0;
}

#external-video1 .more-article-art,
#external-video .more-article-art {
  text-align: right;
  margin-top: 3%;
}

#external-video1 .more-article-art .more-btn-art,
#external-video .more-article-art .more-btn-art {
  border: 2px solid #149ec3;
  box-sizing: border-box;
  border-radius: 40px;
  font-weight: bold;
  font-size: 12px;
  padding: 8px 16px;
  background: #fff;
  color: #149ec3;
  text-decoration: none;
}

#external-video1 .fa-angle-right,
#external-video .fa-angle-right {
  color: #149ec3;
  font-size: 15px;
  margin-left: 0px;
  border: 0;
  border-radius: 0;
}

.bitmap-video h4 {
  font-weight: 600;
  font-size: 14px;
  color: #18364a;
  margin-bottom: 5px;
}

.bitmap-video h6 {
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 12px;
  color: #505762;
}

.bitmap-video p {
  font-weight: 400;
  font-size: 12px;
  color: #4f4f4f;
  margin-top: 8px;
  margin-bottom: 5px;
  word-break: break-all;
}

.bitmap-video {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 20px;
}

.anchore_video {
  color: #337ab7;
}

.pricing-table {
  margin-top: 22px;
}

.pricing-page h3 {
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #149ec3;
  margin-top: 40px;
  margin-bottom: 12px;
}

.pricing-page h1 {
  font-size: 32px;
  text-align: center;
  color: #18364a;
  margin-bottom: 12px;
}

.pricing-page h2 {
  font-size: 22px;
  text-align: center;
  color: #18364a;
  font-weight: 700;
  margin-bottom: 22px;
}

.pricing-page h5 {
  font-weight: 500;
  font-size: 16px;
  color: #18364a;
  margin-bottom: 12px;
}

.pricing-page p {
  font-weight: 400;
  font-size: 14px;
  color: #18364a;
  margin-bottom: 22px;
  margin-left: 9%;
  margin-right: 9%;
}

.pricing-page .place-pricing {
  background: #f2f2f2;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #828282;
  width: 360px;
  height: 81px;
  margin: 0 auto;
  padding: 28px 0px;
}

.pricing-table thead th {
  background: #149ec3;
  font-weight: 600;
  font-size: 24px;
  border-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  text-align: center;
  color: #ffffff;
}

.pricing-table td {
  background: #f9f9f9;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 16px;
  color: #505762;
  width: 127px;
  border: 1px solid #f3f3f3;
}

.pricing-table tbody tr {
  background: #f9f9f9;
  border: 1px solid #f9f9f9;
}

.pricing-table span {
  font-weight: 600;
  font-size: 10px;
  color: #828282;
  background: #e0e0e0;
  border-radius: 9px;
  padding: 0px 7px;
}

.pricing-table .span-yellow {
  font-weight: 600;
  font-size: 10px;
  color: #18364a;
  background: #fcc82b;
  border-radius: 9px;
  padding: 0px 7px;
}

.pricing-table p {
  margin-top: -4px;
}

.pricing-table .price-packages {
  font-weight: bold;
  font-size: 18px;
  color: #18364a;
}

.pricing-table .cbw-price {
  border-top: 2px solid #e0e0e0;
  width: 180px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.pricing-table .cbw-price h3 {
  font-weight: 700;
  font-size: 16px;
  text-align: right;
  color: #2f343a;
  margin-bottom: 15px;
  margin-top: 12px;
}

.pricing-table .cbw-price p {
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  color: #505762;
  margin-left: 6%;
}

.price-tag {
  background: #ffffff !important;
  border: 1px solid #f3f3f3 !important;
}

.price-tag h3 {
  color: #050b37;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 12px;
}

.price-tag p {
  font-weight: 400;
  font-size: 12px;
  align-items: center;
  text-align: center;
  color: #505762;
  margin-bottom: 0px;
}

.price-tag p span {
  color: #27ae60;
  background: #fff;
}

.price-tag .buy-btn1 {
  background: #b8e2ed;
  border: 2px solid #056984;
  border-radius: 40px;
  font-weight: 600;
  font-size: 9px;
  border-radius: 40px;
  text-decoration: none !important;
  color: #056984;
  padding: 6px 30px;
  text-transform: uppercase;
  display: block;
  margin-top: 11%;
}

.price-tag .buy-btn {
  font-weight: 600;
  font-size: 9px;
  background: #fff;
  border-radius: 40px;
  text-decoration: none !important;
  color: #149ec3;
  padding: 6px 30px;
  text-transform: uppercase;
  border: 2px solid #149ec3;
  display: block;
  margin-top: 26%;
}

.scroll-price {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  /* overflow: scroll; */
  /* height: 2000px; */
  background: #fff;
}

.scroll-price::-webkit-scrollbar {
  display: none;
}

#save_money {
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
  background: #18364a;
  border: 1px solid #f2f2f2;
  padding: 4px;
}

.canvasjs-chart-credit {
  display: none;
}

.checkmark-class {
  border-radius: 3px !important;
}

.checkedmarking-class .checkmark-class:after {
  left: 4px !important;
  top: 1px !important;
}

.pricing-table .price_heading h3 {
  font-weight: 600;
  font-size: 17px;
  text-transform: uppercase;
  color: #18364a;
  margin-top: -3px;
  margin-left: 3px;
}

.pricing-table .para_prices p {
  font-size: 12px;
  color: #3f3f3f;
  background: #f9f9f9;
  border: 0px solid #f3f3f3;
  text-align: left;
}

.pricing-table .price_heading {
  background: #f3f3f3;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-bottom: 0px;
}

.pricing-table .para_prices {
  padding-bottom: 0px;
}

.pricing-table .h5_price h5 {
  font-weight: 400;
  font-size: 14px;
  color: #3f3f3f;
  background: #f9f9f9;
}

.pricing-table .price_tag,
.pricing-table .price_tag1 {
  background: #fff;
}

.pricing-table .price_tag h6 {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #505762;
}

.pricing-table .price_tag1 h6 {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #505762;
}

.pricing-table .faet_price h4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #18364a;
}

.pricing-table .faet_price {
  background: #f3f3f3;
}

.people .people-page h2 {
  margin-top: 40px;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  color: #050b37;
}

.people-list-acn {
  font-weight: 600;
  font-size: 14px;
  color: #149ec3;
}

.people .people-page p {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #18364a;
  margin: 0 17%;
  margin-bottom: 27px;
}

.people .select-input-contact {
  font-weight: 400;
  font-size: 12px;
  color: #18364a;
}

.people .people-img {
  position: absolute;
  top: 63px;
  width: 345px !important;
  height: 110px !important;
  right: -20%;
}

#people h5 {
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  margin-top: 20px;
  margin-left: 0%;
  color: #ffffff;
}

#people p {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  margin: 0 17%;
  margin-bottom: 15px;
}

#people .banner-content .banner-textover {
  grid-area: overflow;
  margin-top: -3%;
  text-align: center;
}

#people .banner-content .main_image_banner {
  margin-top: 9px;
  grid-area: overflow;
}

#people .view-inspiration {
  font-weight: 600;
  font-size: 12px;
  align-items: center;
  text-align: center;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 40px;
  margin-bottom: 20px;
  padding: 7px 25px;
  margin-left: -44px;
}

.people .press-ul {
  list-style-type: none;
  margin-left: 0px;
  float: right;
  margin-top: 12px;
}

.people .press-li {
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  color: #4f4f4f;
}

.people .input-group .border-lable-flt .form-control {
  border: 0.75px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 4px;
}

.people .filter-option {
  display: block;
  /* margin-left: 20px; */
}

.people .people-filter p {
  font-weight: 600;
  font-size: 10px;
  color: #4f4f4f;
}

.people-filter {
  margin-top: 4%;
}

.people .people-info {
  display: inline-flex;
  /* flex-direction: row; */
  // background: url(.png);
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.05));
  border-radius: 4px;
  display: none;
}

.people .people-info h3 {
  font-weight: 600;
  font-size: 14px;
  color: #149ec3;
  margin-top: 12px;
}

.people .people-info .peopel-img {
  margin-top: 15px;
  margin-left: 10px;
}

.people .people-info h5 {
  font-weight: 600;
  font-size: 12px;
  color: #18364a;
  margin-top: 23px;
}

.people .people-info p {
  font-weight: normal;
  font-size: 14px;
  color: #505762;
  margin-right: 22px;
}

.people .people-info .load-people {
  font-weight: normal;
  font-size: 10px;
  color: #337ab7;
}

.people .load-load {
  margin-top: 33px;
  padding-bottom: 40px;
}

.people .load-people-more {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #18364a;
}

.people .load-people-more img {
  margin-right: 4px;
}

.people .people-border {
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  margin-top: 17px;
  padding-bottom: 9px;
  padding-top: 5px;
}

.people .people-space {
  margin-right: 12px;
}

.people-hide {
  display: none;
}

.inspiration-detail .inspiration-img img {
  border-radius: 4px;
  margin-left: 25%;
  position: relative;
  z-index: 1;
  width: 53% !important;
  height: 33%;
}

.inspiration-detail .inspiration-word h1 {
  font-weight: 600;
  font-size: 22px;
  color: #149ec3;
  margin-left: -16%;
}

.inspiration-detail .inspiration-word h3 {
  font-weight: 500;
  font-size: 15px;
  color: #18364a;
  margin-left: -16%;
  margin-bottom: 20px;
}

.inspiration-detail .inspiration-word h6 {
  font-weight: 300;
  font-size: 16px;
  color: #505762;
  line-height: 24px;
}

.inspiration-detail .inspiration-word h2 {
  font-weight: 600;
  font-size: 19px;
  color: #18364a;
}

.inspiration-detail .inspiration-word p {
  font-weight: normal;
  font-size: 14px;
  color: #18364a;
}

.inspiration-detail .inspiration-word .anchore-insp {
  font-weight: 300;
  font-size: 14px;
  color: #337ab7;
}

.inspiration-detail .inspiration-word .biography-inspiration {
  margin-top: 7%;
}

.inspiration-detail .inspiration-word .anchore-insp img {
  margin-right: 7px;
  margin-top: -2px;
}

.inspiration-detail .inspiration-background {
  background: #f3f3f3;
  /* display: none; */
  /* position: relative; */
  /* top: -15px; */
  /* left: 0%; */
  z-index: 0;
}

.inspiration-detail .inspiration-background .inspiration-margin {
  margin-top: 35px;
  margin-bottom: 25px;
}

.inspiration-detail .inspiration-info .inspiration-margin-second {
  margin-top: 124%;
}

.inspiration-detail .inspiration-background h4 {
  font-weight: 600;
  font-size: 13px;
  color: #050b37;
  margin-top: 17px;
}

.inspiration-detail .inspiration-background h6 {
  font-weight: 400;
  font-size: 14px;
}

.inspiration-detail .inspiration-info {
  background: #fcfbfb;
  background: #fcfbfb;
  position: absolute;
  top: 8%;
  right: 0;
  z-index: 0;
}

.inspiration-detail .inspiration-info h6 {
  font-weight: 400;
}

.inspiration-detail .inspiration-info .press-ul {
  list-style-type: none;
  margin-left: -36%;
  float: left;
  margin-top: 12px;
}

.people .view-inspiration {
  font-weight: 600;
  font-size: 12px;
  align-items: center;
  text-align: center;
  color: #149ec3;
  border: 2px solid #149ec3;
  border-radius: 40px;
  margin-bottom: 20px;
  padding: 7px 25px;
}

.people-filter ul {
  list-style-type: none;
  display: inline-block;
}

.people-filter ul li {
  display: inline-block;
}

.people-filter .fa-angle-left,
.people-filter .fa-angle-right {
  color: #bdbdbd;
  font-size: 20px;
  margin-right: 0px;
  border-radius: 3px;
  padding: 5px 11px;
  background: #f2f2f2;
  border: 0px;
  margin-left: 0;
}

.people-filter .active {
  background: #ffdb6d;
  color: #505762;
}

.people-filter li a span:hover {
  background: #ffdb6d;
  color: #505762;
}

.view-inspiration-theory {
  margin-top: 20px;
  margin-bottom: 20px;
}

.inspiration-way-col .col-lg-2 {
  flex: 0 0 18.666667%;
  max-width: 18.666667%;
}

.inspiration-way-col .card {
  width: 100% !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  background-color: #ffffff !important;
}

.inspiration-way-col .image-respo {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  width: 100%;
  border-radius: 4px;
}

.inspiration-way-col .video-rel-col span {
  font-size: 8px;
  color: #9b9b9b;
  margin-bottom: 8px;
  margin-left: 0;
}

.inspiration-way-col .video-rel-col h4 {
  font-weight: 600;
  font-size: 14px;
  color: #18364a;
  margin-right: -8px;
  line-height: 19px;
  margin-top: 5px;
  margin-left: 3px;
  clear: both;
}

.inspiration-way-col .video-rel-col {
  border-right: 0px solid #e0e0e0;
}

.Disclaimer-insp {
  font-weight: 400;
  font-size: 10px;
  color: #18364a;
  background: #f2f2f2;
  padding: 12px;
}

.arrow-div-people {
  margin-top: -5%;
  z-index: 1;
  position: absolute;
  right: 9%;
}

#disclaimer .disclaimer-content1 {
  padding: 23px 60px;
  font-size: 10px;
  height: auto;
  background: #f2f2f2;
}

#carouselExampleIndicators2 .carousel-item a img {
  height: fit-content;
  overflow: hidden;
}

#carouselExampleIndicators2 .carousel-inner img {
  width: auto;
}

#carouselExampleIndicators2 .carousel-item {
  margin-right: 0 !important;
}

.currencies .currencies-page h2 {
  margin-top: 40px;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
  color: #050b37;
}

.currencies .currencies-page p {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #18364a;
  margin: 0 17%;
  margin-bottom: 27px;
}

.currencies .currencies-filter {
  margin-top: 0%;
}

.currencies .input-group .border-lable-flt .form-control {
  border: 0.75px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  color: #18364a;
  height: 35px;
}

.currencies .currencies-border {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding-top: 26px;
  margin-bottom: 16px;
  margin-right: 0px;
  margin-left: 0px;
}

.currencies .currencies-space {
  margin-left: -44px;
}

.currencies .currencies-space2 {
  margin-left: 38px;
}

.currencies .currencies-space2 i {
  float: right;
  font-size: 16px;
  font-weight: 500;
  margin-top: -27px;
  margin-right: 13px;
  color: #505762;
}

.currencies .currencies-space2 .serach-css {
  font-size: 12px;
  color: #9b9b9b;
  height: 35px;
}

.currencies .currencies-space2 .currencies-label {
  font-size: 10px;
  color: #18364a;
}

.currencies .currencies-co p {
  font-weight: 600;
  font-size: 12px;
  color: #9b9b9b;
}

.currencies .currencies-co .float-left {
  padding: 12px 0px;
}

.currencies .currencies-co .float-right {
  padding: 8px 0px;
}

.currencies .currencies-co .reset-currency {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #18364a;
}

.currencies .currency-width {
  width: 95px;
}

.currencies .currencies-co {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: auto;
}

.currencies .currencies-filter .col-md-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 15.666667%;
  max-width: 15.666667%;
  margin-left: 10px;
}

.currencies .curre-border {
  border: 1px solid #e0e0e0;
  clear: both;
  width: 177px;
  margin-left: -15px;
}

.currencies .select-currency {
  font-size: 12px;
  height: 35px;
  margin-top: 12px;
  margin-bottom: 12px;
  color: #9b9b9b;
}

.currencies .view-currencies {
  font-weight: 600;
  font-size: 10px;
  align-items: center;
  text-align: center;
  color: #149ec3;
  border: 2px solid #149ec3;
  border-radius: 40px;
  margin-bottom: 20px;
  padding: 7px 18px;
}

.currencies .currencies-button {
  padding: 17px 11px;
}

.currencies .scroll-cureency {
  height: 575px;
  /* overflow: scroll; */
}

.currencies .custom-select {
  border: 0px solid #ced4da !important;
  color: #828282;
  font-size: 10px;
}

.currencies .select-input-contact option {
  font-size: 14px;
  padding: 7px;
  color: #18364a;
}

.currencies .select-input-contact option:hover {
  font-size: 14px;
  background: #f9f9f9;
}

.currencies .currencies-crypto h6 {
  font-size: 14px;
  color: #18364a;
  font-weight: 400;
  text-align: left;
}

.currencies .currencies-blue {
  color: #149ec3;
  font-weight: 400;
  font-size: 14px;
}

.currencies .currencies-red {
  color: #eb5757;
  font-weight: 400;
  font-size: 14px;
}

.currencies .currencies-green {
  font-weight: 400;
  font-size: 14px;
  color: #27ae60;
}

.currencies .table-responsive {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding-top: 0px;
  margin-bottom: 16px;
  margin-right: 14px;
  margin-left: 14px;
}

.currencies .table {
  width: 100%;
  margin-bottom: 0rem;
}

.currencies .currency-circle {
  margin-left: -28px;
  margin-right: 4px;
}

.currencies .currencies-pagination {
  margin-left: 54px;
}

.currencies .currencies-below-space {
  margin-bottom: 68px;
}

.currencies .currencies-crypto .checkmark-class {
  border-radius: 3px !important;
  background: #fff;
  border: 1px solid #ccc;
}

.currencies .bookmark-currencies {
  font-weight: 600;
  font-size: 12px;
  align-items: center;
  text-align: center;
  /* background: #149ec3; */
  border: 2px solid #149ec3;
  margin-bottom: 20px;
  padding: 8px 25px;
  color: #149ec3;
  box-sizing: border-box;
  border-radius: 40px;
}

/* .currencies-space2 .bookmark-currencies {
  font-weight: 600;
  font-size: 12px;
  align-items: center;
  text-align: center;
  background-color: #fff;
} */
.currencies .input-group .border-lable-flt .select-input-contact1 {
  border: 0px !important;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  color: #149ec3 !important;
  height: 35px;
  width: 175px;
  margin-left: -10px;
}

.currencies .input-group .border-lable-flt .select-input-contact1 option {
  font-size: 14px;
  padding: 5px;
  color: #18364a;
}

.currencies .input-group .border-lable-flt .select-input-contact1 option:hover {
  background: #f9f9f9;
}

.currencies .input-group .border-lable-flt .select-input-contact1 :hover {
  background: #f9f9f9;
}

.currencies .select-currency h6 {
  font-weight: 600;
  font-size: 12px;
  color: #828282;
}

.currencies .view-currency {
  margin: auto;
  width: 100%;
  border: 1px solid #e0e0e0;
  margin-bottom: 15px;
}

.currencies .wrapper-currency {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}

.currencies .sticky-col {
  position: sticky;
  position: -webkit-sticky;
  background-color: white;
}

.currencies .first-col {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 0px;
}

.currencies .second-col {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 100px;
}

#myBlueDIV .breaking-new {
  width: 120px !important;
}

.become .become-ea h1 {
  font-weight: 600;
  font-size: 26px;
  color: #ffffff;
  text-align: center;
}

.become .become-ea .banner-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "overflow";
}

.become .become-ea .banner-content .main_image_banner {
  grid-area: overflow;
}

.become .become-ea .banner-content .main_image_banner img {
  width: 100%;
}

.become .become-ea .banner-content .banner-textover {
  grid-area: overflow;
  margin-top: 0%;
  margin-left: 0%;
}

.become .become-ea .cbw-image-slider {
  margin-right: -15px;
  margin-left: -15px;
}

.become .become-text {
  margin-top: 3%;
}

.become .become-text h2 {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #18364a;
  margin-bottom: 15px;
}

.become .become-text p {
  font-size: 14px;
  text-align: center;
  color: #18364a;
  margin-bottom: 20px;
  margin-left: 10%;
  margin-right: 10%;
}

.become .become-text .enroll-button {
  text-align: center;
  margin-bottom: 6%;
}

.become .enroll-button1 {
  margin-bottom: 4% !important;
  margin-top: 4% !important;
}

.become .become-text .enroll-button .become-enroll {
  background: #149ec3;
  border-radius: 40px;
  font-weight: 600;
  font-size: 12px;
  padding: 10px 35px;
  text-transform: uppercase;
  color: #ffffff;
}

.become .become-coloum {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-right: 10px;
  border-left: 3px solid #14b4ca;
  border-top-left-radius: 0px;
  border-bottom: 3px solid #14b4ca;
  border-bottom-right-radius: 0px;
}

.become .odd {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-right: 10px;
  border-right: 3px solid #14b4ca;
  border-top-right-radius: 0px;
  border-bottom: 3px solid #14b4ca;
  border-bottom-left-radius: 0px;
  border-left: 0px !important;
}

.become .become-coloum .becme-border h5 {
  font-weight: 600;
  font-size: 16px;
  color: #050b37;
  margin-top: 12%;
  margin-bottom: 10px;
  margin-left: 25px;
}

.become .become-coloum .becme-border p {
  font-weight: 400;
  font-size: 14px;
  color: #18364a;
  margin-left: 25px;
  margin-right: 10%;
  padding-bottom: 15%;
}

.become .become-coloum .become-img {
  position: absolute;
  top: -45px;
}

.become .become-space {
  margin-top: 10%;
}

.become .become-carrer {
  background: #f9f9f9;
  margin-top: 4%;
  padding: 30px;
}

.become .become-carrer h5 {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #4f4f4f;
}

.become .become-carrer p {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #18364a;
  margin: 9px 15%;
}

.become .enroll-button1 {
  text-align: center;
  /* margin-bottom: 3%; */
  /* margin-top: 3%; */
}

.become .enroll-button1 .become-enroll1 {
  background: #149ec3;
  border-radius: 40px;
  font-weight: 600;
  font-size: 12px;
  padding: 10px 35px;
  text-transform: uppercase;
  color: #ffffff;
}

.people-press-ul {
  list-style-type: none;
  margin-left: 0px;
  float: right;
  margin-top: 12px;
}

.currency-summary-back {
  background: #f2f2f2;
  height: 316px;
  width: 100%;
}

.currency-chart {
  width: 100%;
  height: 400px;
  text-align: center;
  background: #f2f2f2;
  color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.currency-button {
  border: 2px solid #149ec3;
  border-radius: 40px;
  font-family: Poppins;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  padding: 7px 25px;
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #149ec3;
  /* width: 100px; */
}

a.disabled {
  pointer-events: none;
}

.grey-bg {
  background: #f8f8f8;
  height: 125px;
  padding: 40px;
  text-align: center;
}

.event-body .top .grey-bg ul li {
  display: inline-block;
  width: 150px;
}

.bgimage {
  width: 97.5%;
  margin-left: 14px;
  height: 300px;
  background: url("/src/assets/images/AmericaBanner.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.currencies-detais .dropdown-currencies {
  margin-left: -23px;
}

.currencies-detais .currenecies-heading h1 {
  font-weight: 600;
  font-size: 30px;
  color: #050b37;
}

.currencies-detais .currenecies-heading p {
  font-weight: 400;
  margin-top: -1%;
  margin-left: 1px;
  opacity: 0.9;
  font-size: 12px;
  color: #828282;
}

.currencies-detais .currencies-search input {
  font-size: 12px;
  color: #9b9b9b;
}

.currencies-detais .currencies-search .fontuser1 i {
  position: absolute;
  right: 23px;
  top: 9px;
  color: #505762;
  font-size: 19px;
  font-weight: 400;
}

.currencies-detais .currenecies-price ul {
  list-style: none;
  margin-left: -51px;
  margin-top: 15px;
  display: inline-block;
}

.currencies-detais .currenecies-price li {
  display: inline-block;
  padding: 0px 34px;
  border-left: 1px dashed #e0e0e0;
}

.currencies-detais .currenecies-price li:first-child {
  border-left: 0px dashed #e0e0e0;
  margin-left: -21px;
}

.currencies-detais .currenecies-price li p {
  font-weight: 400;
  font-size: 14px;
  color: #4f4f4f;
  opacity: 0.9;
  color: #4f4f4f;
  opacity: 0.9;
}

.currencies-detais .currenecies-price li h1 {
  font-weight: 600;
  font-size: 27px;
  color: #18364a;
  font-size: 27px;
  color: #18364a;
}

.currencies-detais .currenecies-price li h4 {
  font-weight: 600;
  font-size: 22px;
  color: #18364a;
  color: #18364a;
}

.currencies-detais .currenecies-price li .red {
  color: #d63939 !important;
  font-size: 22px;
}

.currencies-detais .currenecies-price li .caret {
  margin-right: 6px;
}

.currencies-detais .currenecies-price h3 .green {
  font-size: 22px;
  color: #27ae60 !important;
}

.currencies-detais .currencies-share {
  margin-top: 22px;
}

.currencies-detais .currencies-share .dropdown-currencies .dropdown-menu {
  min-width: 1rem !important;
}

.currencies-detais .currencies-search input {
  height: 35px;
}

.currencies-detais .currencies-share .fullscreen a {
  font-weight: 600;
  font-size: 12px;
  color: #18364a;
  word-spacing: -2px;
  text-transform: uppercase;
}

.currencies-detais .currencies-share .dropdown-currencies {
  margin-left: 13px;
}

.currencies-detais .currencies-share .social-icon-share {
  margin-left: -3px;
}

.currencies-detais .currencies-share .fullscreen {
  text-align: right;
}

.currencies-detais .currency-chart {
  background: #f2f2f2;
  height: 400px;
}

.currencies-detais .currency-chart h1 {
  font-weight: 900;
  font-size: 40px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #e0e0e0;
  padding: 15% 0;
  text-align: center;
  border-bottom: 1px solid #f2f2f2;
}

.currencies-detais .currencies-depth {
  margin-left: 0px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.currencies-detais #datetimepicker4 input {
  font-size: 12px;
  border-right: 0px;
}

.currencies-detais #datetimepicker4 {
  width: 128px;
}

.currencies-detais #datetimepicker2 {
  width: 128px;
  margin-left: -23px;
}

.currencies-detais #datetimepicker4 .input-group-text {
  background-color: #fff !important;
  border-left: 1px solid #fff !important;
}

.currencies-detais #datetimepicker2 .input-group-text {
  background-color: #fff !important;
  border-left: 1px solid #fff !important;
}

.currencies-detais #datetimepicker2 input {
  font-size: 12px;
  border-right: 0px;
}

.currencies-detailsss .currency-summary h1 {
  font-weight: 600;
  font-size: 20px;
  color: #18364a;
  margin-bottom: 20px;
  margin-top: 32px;
}

.currencies-detailsss .currency-summary-back {
  height: 316px;
  background: #f2f2f2;
}

.currencies-detailsss .currency-summary p {
  font-weight: normal;
  font-size: 14px;
  color: #18364a;
  margin-bottom: 12px;
}

.currencies-detailsss .currency-summary {
  margin-top: 60px;
}

.currencies-detailsss .currency-button {
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  text-align: center;
  text-transform: uppercase;
  color: #149ec3;
  border: 2px solid #149ec3;
  box-sizing: border-box;
  border-radius: 40px;
  padding: 8px 30px;
}

.currencies-detais .select-input-contact1 {
  font-weight: 400;
  font-size: 12px;
  color: #18364a;
  border: 1px solid #ced4da;
  width: 90px;
  height: 35px;
}

.currencies-break {
  padding-bottom: 12%;
}

.currencies-detais .currencies-day-desp ul {
  list-style: none;
  display: inline-block;
}

.currencies-detais .currencies-day-desp li {
  font-weight: 500;
  font-size: 12px;
  text-align: right;
  color: #18364a;
  padding: 8px;
  display: inline-block;
}

.currencies-detais .currencies-day-desp .active {
  background: #b8e2ed;
  border-radius: 4px;
}

.currencies-detais .currencies-day-desp li:hover {
  background: #b8e2ed;
  border-radius: 4px;
}

.currencies-detais .currencies-day-desp {
  margin-top: 30px;
  /* margin-left: 57px; */
  margin-bottom: 2px;
}

.currencies-detais .select-input-contact2 {
  font-weight: 400;
  font-size: 12px;
  color: #18364a;
  border: 1px solid #ced4da;
  width: 127px;
  height: 35px;
}

.menu .navbar-logo {
  height: 50px;
}

.modal-dialog .modal-heading {
  display: flex;
  width: 100%;
  padding-left: 10px;
}

.modal-dialog .modal-heading .left-logo {
  width: 20%;
}

.modal-dialog .modal-heading .center-part {
  width: 70%;
  text-align: center;
}

.modal-dialog .modal-heading .right-part {
  width: 10%;
  padding-right: 10px;
}

.event-crypto-heading {
  font-size: 19px !important;
  font-weight: 600;
}

.event-crypto-line {
  background-color: #007bff;
  height: 4px;
  width: 70px;
  /* margin-left: -35px; */
}

.banner-textover1 img {
  height: auto !important;
}

.news-bac-white2 h3 {
  margin-bottom: 10% !important;
}

.currencies .currencies-page h2 {
  margin-top: 40px;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
  color: #050b37;
}

.currencies .currencies-page p {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #18364a;
  margin: 0 17%;
  margin-bottom: 27px;
}

.currencies .currencies-filter {
  margin-top: 0%;
}

.currencies .input-group .border-lable-flt .form-control {
  border: 0.75px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  color: #18364a;
  height: 35px;
}

.currencies-border-box .border-lable-flt .drop-all {
  border: 0.75px solid #bdbdbd;
  width: 150px;
}

.currencies-border-box .publish-btn-icon-curr {
  position: absolute;
  right: 0px !important;
  top: 50%;
}

.border-lable-flt .dropdown-prss .dropdown-toggle {
  margin-left: -20px;
}

.currencies .currencies-border {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding-top: 26px;
  margin-bottom: 16px;
  margin-right: 0px;
  margin-left: 0px;
}

.currencies .currencies-space {
  margin-left: -44px;
}

.currencies .currencies-space2 {
  margin-left: 38px;
}

.currencies .currencies-space2 i {
  float: right;
  font-size: 16px;
  font-weight: 500;
  margin-top: -27px;
  margin-right: 13px;
  color: #505762;
}

.currencies .currencies-space2 .serach-css {
  font-size: 12px;
  color: #9b9b9b;
  height: 35px;
}

.currencies .currencies-space2 .currencies-label {
  font-size: 10px;
  color: #18364a;
}

.currencies-space .dropdown-prss {
  border: 0.75px solid #bdbdbd;
}

.pr-curr {
  color: #18364a;
}

.currencies .currencies-co p {
  font-weight: 600;
  font-size: 12px;
  color: #9b9b9b;
}

.currencies .currencies-co .float-left {
  padding: 12px 0px;
}

.currencies .currencies-co .float-right {
  padding: 8px 0px;
}

.currencies .currencies-co .reset-currency {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #18364a;
}

.currencies .currency-width {
  width: 95px;
}

.currencies .currencies-co {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: auto;
}

.currencies .currencies-filter .col-md-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 15.666667%;
  max-width: 15.666667%;
  margin-left: 10px;
}

.curr-border {
  border-right: 1px solid #e0e0e0;
}

.curr-border2 {
  border-right: 1px solid #e0e0e0;
  width: 117px;
}

.curr-border #inlineFormCustomSelect {
  margin-left: -9px;
}

.circul-supply {
  width: 162px;
}

.currencies .curre-border {
  border: 1px solid #e0e0e0;
  clear: both;
  width: 177px;
  margin-left: -15px;
}

.currencies .select-currency {
  font-size: 12px;
  height: 35px;
  margin-top: 12px;
  margin-bottom: 12px;
  color: #9b9b9b;
}

.currencies .view-currencies {
  font-weight: 600;
  font-size: 10px;
  align-items: center;
  text-align: center;
  color: #149ec3;
  border: 2px solid #149ec3;
  border-radius: 40px;
  margin-bottom: 20px;
  padding: 7px 18px;
}

.currencies .currencies-button {
  padding: 17px 11px;
}

.currencies .scroll-cureency {
  height: 575px;
  /* overflow: scroll; */
}

.currencies .scroll-cureency .dropdown {
  border: 0.75px solid #bdbdbd;
  border-radius: 4px;
}

.currencies .scroll-cureency .arrow {
  position: absolute;
  right: 10px;
  top: 30%;
}

.currencies .custom-select {
  border: 0px solid #ced4da !important;
  color: #828282;
  font-size: 10px;
}

.currencies .select-input-contact option {
  font-size: 14px;
  padding: 7px;
  color: #18364a;
}

.currencies .select-input-contact option:hover {
  font-size: 14px;
  background: #f9f9f9;
}

.currencies .currencies-crypto h6 {
  font-size: 14px;
  color: #18364a;
  font-weight: 400;
  text-align: left;
}

.currencies .currencies-blue a,
.currencies .currencies-blue {
  color: #149ec3;
  font-weight: 400;
  font-size: 14px;
}

.currencies .currencies-red {
  color: #eb5757;
  font-weight: 400;
  font-size: 14px;
}

.currencies .currencies-green {
  font-weight: 400;
  font-size: 14px;
  color: #27ae60;
}

.currencies .table-responsive {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  padding-top: 0px;
  margin-bottom: 16px;
  margin-right: 14px;
  margin-left: 14px;
}

.currencies .table {
  width: 100%;
  margin-bottom: 0rem;
}

.currencies .currency-circle {
  margin-left: -28px;
  margin-right: 4px;
}

.currencies .currencies-pagination {
  margin-left: 54px;
}

.currencies .currencies-below-space {
  margin-bottom: 68px;
}

.currencies .currencies-crypto .checkmark-class {
  border-radius: 3px !important;
  background: #fff;
  border: 1px solid #ccc;
}

.currencies .bookmark-currencies {
  font-weight: 600;
  font-size: 12px;
  align-items: center;
  text-align: center;
  /* background: #149ec3; */
  border: 2px solid #149ec3;
  margin-bottom: 20px;
  padding: 8px 25px;
  color: #149ec3;
  box-sizing: border-box;
  border-radius: 40px;
}

/* .currencies-space2 .bookmark-currencies {
  font-weight: 600;
  font-size: 12px;
  align-items: center;
  text-align: center;
  background-color: #fff;
} */
.currencies .input-group .border-lable-flt .select-input-contact1 {
  border: 0px !important;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  color: #149ec3 !important;
  height: 35px;
  width: 175px;
  margin-left: -10px;
}

.currencies .input-group .border-lable-flt .select-input-contact1 option {
  font-size: 14px;
  padding: 5px;
  color: #18364a;
}

.currencies .input-group .border-lable-flt .select-input-contact1 option:hover {
  background: #f9f9f9;
}

.currencies .input-group .border-lable-flt .select-input-contact1 :hover {
  background: #f9f9f9;
}

.currencies .select-currency h6 {
  font-weight: 600;
  font-size: 12px;
  color: #828282;
}

.currencies .view-currency {
  margin: auto;
  width: 100%;
  border: 1px solid #e0e0e0;
  margin-bottom: 15px;
}

.currencies .wrapper-currency {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}

.currencies .sticky-col {
  position: sticky;
  position: -webkit-sticky;
  background-color: white;
}

.currencies .first-col {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 0px;
}

.currencies .second-col {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
  left: 100px;
}

.index-overview .banner-textover h1 {
  font-weight: 600;
  font-size: 26px;
  color: #ffffff;
  text-align: center;
}

.index-overview .main_image_banner img {
  height: auto !important;
  width: 100%;
}

.index-overview .become-ea .banner-content .banner-textover {
  grid-area: overflow;
  margin-top: 0%;
  margin-left: 0%;
}

.index-overview .become-ea .banner-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "overflow";
}

#cbw-image-slider {
  margin-top: -7px !important;
  margin-right: -15px;
  margin-left: -15px;
}

.index-page {
  background: #f9f9f9;
  margin-top: 10px;
  padding: 30px;
}

.index-overview {
  margin-top: 15px;
}

.index-page h2 {
  font-weight: 600;
  font-size: 22px;
  text-align: center;
  color: #18364a;
  margin-top: 5px;
}

.index-page p {
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  color: #18364a;
  margin: 5px 8%;
}

.index-overview select {
  font-size: 12px;
}

.index-graph-border {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 18px;
}

#myBlueDIV .breaking-new {
  width: 120px !important;
}

.become .become-ea h1 {
  font-weight: 600;
  font-size: 26px;
  color: #ffffff;
  text-align: center;
}

.become .become-ea .banner-content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "overflow";
}

.become .become-ea .banner-content .main_image_banner {
  grid-area: overflow;
}

.become .become-ea .banner-content .main_image_banner img {
  width: 100%;
}

.become .become-ea .banner-content .banner-textover {
  grid-area: overflow;
  margin-top: 0%;
  margin-left: 0%;
}

.become .become-ea .cbw-image-slider {
  margin-right: -15px;
  margin-left: -15px;
}

.become .become-text {
  margin-top: 3%;
}

.become .become-text h2 {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #18364a;
  margin-bottom: 15px;
}

.become .become-text p {
  font-size: 14px;
  text-align: center;
  color: #18364a;
  margin-bottom: 20px;
  margin-left: 10%;
  margin-right: 10%;
}

.become .become-text .enroll-button {
  text-align: center;
  margin-bottom: 6%;
}

.become .enroll-button1 {
  margin-bottom: 4% !important;
  margin-top: 4% !important;
}

.become .become-text .enroll-button .become-enroll {
  background: #149ec3;
  border-radius: 40px;
  font-weight: 600;
  font-size: 12px;
  padding: 10px 35px;
  text-transform: uppercase;
  color: #ffffff;
}

.become .become-coloum {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-right: 10px;
  border-left: 3px solid #14b4ca;
  border-top-left-radius: 0px;
  border-bottom: 3px solid #14b4ca;
  border-bottom-right-radius: 0px;
}

.become .odd {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-right: 10px;
  border-right: 3px solid #14b4ca;
  border-top-right-radius: 0px;
  border-bottom: 3px solid #14b4ca;
  border-bottom-left-radius: 0px;
  border-left: 0px !important;
}

.become .become-coloum .becme-border h5 {
  font-weight: 600;
  font-size: 16px;
  color: #050b37;
  margin-top: 12%;
  margin-bottom: 10px;
  margin-left: 25px;
}

.become .become-coloum .becme-border p {
  font-weight: 400;
  font-size: 14px;
  color: #18364a;
  margin-left: 25px;
  margin-right: 10%;
  padding-bottom: 15%;
}

.become .become-coloum .become-img {
  position: absolute;
  top: -45px;
}

.become .become-space {
  margin-top: 10%;
}

.become .become-carrer {
  background: #f9f9f9;
  margin-top: 4%;
  padding: 30px;
}

.become .become-carrer h5 {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #4f4f4f;
}

.become .become-carrer p {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #18364a;
  margin: 9px 15%;
}

.become .enroll-button1 {
  text-align: center;
  /* margin-bottom: 3%; */
  /* margin-top: 3%; */
}

.become .enroll-button1 .become-enroll1 {
  background: #149ec3;
  border-radius: 40px;
  font-weight: 600;
  font-size: 12px;
  padding: 10px 35px;
  text-transform: uppercase;
  color: #ffffff;
}

.people-press-ul {
  list-style-type: none;
  margin-left: 0px;
  float: right;
  margin-top: 12px;
}

.currency-summary-back {
  background: #f2f2f2;
  height: 316px;
  width: 100%;
}

.currency-chart {
  width: 100%;
  height: 400px;
  text-align: center;
  background: #f2f2f2;
  color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.currency-button {
  border: 2px solid #149ec3;
  border-radius: 40px;
  font-family: Poppins;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  padding: 7px 25px;
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #149ec3;
  /* width: 100px; */
}

.grey-bg {
  background: #f8f8f8;
  height: auto;
  padding: 40px;
  text-align: center;
}

.event-body .top .grey-bg ul li {
  display: inline-block;
  width: 150px;
}

.bgimage {
  width: 97.5%;
  margin-left: 14px;
  height: 300px;
  background: url("/src/assets/images/AmericaBanner.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.currencies-detais .dropdown-currencies {
  margin-left: -23px;
}

.currencies-detais .currenecies-heading h1 {
  font-weight: 600;
  font-size: 30px;
  color: #050b37;
}

.currencies-detais .currenecies-heading p {
  font-weight: 400;
  margin-top: -1%;
  margin-left: 1px;
  opacity: 0.9;
  font-size: 12px;
  color: #828282;
}

.currencies-detais .currencies-search input {
  font-size: 12px;
  color: #9b9b9b;
}

.currencies-detais .currencies-search .fontuser1 i {
  position: absolute;
  right: 23px;
  top: 9px;
  color: #505762;
  font-size: 19px;
  font-weight: 400;
}

.currencies-detais .currenecies-price ul {
  list-style: none;
  margin-left: -51px;
  margin-top: 15px;
  display: inline-block;
}

.currencies-detais .currenecies-price li {
  display: inline-block;
  padding: 0px 34px;
  border-left: 1px dashed #e0e0e0;
}

.currencies-detais .currenecies-price li:first-child {
  border-left: 0px dashed #e0e0e0;
  margin-left: -21px;
}

.currencies-detais .currenecies-price li p {
  font-weight: 400;
  font-size: 14px;
  color: #4f4f4f;
  opacity: 0.9;
  color: #4f4f4f;
  opacity: 0.9;
}

.currencies-detais .currenecies-price li h1 {
  font-weight: 600;
  font-size: 27px;
  color: #18364a;
  font-size: 27px;
  color: #18364a;
}

.currencies-detais .currenecies-price li h4 {
  font-weight: 600;
  font-size: 22px;
  color: #18364a;
  color: #18364a;
}

.currencies-detais .currenecies-price li .red {
  color: #d63939 !important;
  font-size: 22px;
}

.currencies-detais .currenecies-price li .caret {
  margin-right: 6px;
}

.currencies-detais .currenecies-price h3 .green {
  font-size: 22px;
  color: #27ae60 !important;
}

.currencies-detais .currencies-share {
  margin-top: 22px;
}

.currencies-detais .currencies-share .dropdown-currencies .dropdown-menu {
  min-width: 1rem !important;
}

.currencies-detais .currencies-search input {
  height: 35px;
}

.currencies-detais .currencies-share .fullscreen a {
  font-weight: 600;
  font-size: 12px;
  color: #18364a;
  word-spacing: -2px;
  text-transform: uppercase;
}

.currencies-detais .currencies-share .dropdown-currencies {
  margin-left: 13px;
}

.currencies-detais .currencies-share .social-icon-share {
  margin-left: -3px;
}

.currencies-detais .currencies-share .fullscreen {
  text-align: right;
}

.currencies-detais .currency-chart {
  background: #f2f2f2;
  height: 400px;
}

.currencies-detais .currency-chart h1 {
  font-weight: 900;
  font-size: 40px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #e0e0e0;
  padding: 15% 0;
  text-align: center;
  border-bottom: 1px solid #f2f2f2;
}

.currencies-detais .currencies-depth {
  margin-left: 0px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.currencies-detais #datetimepicker4 input {
  font-size: 12px;
  border-right: 0px;
}

.currencies-detais #datetimepicker4 {
  width: 128px;
}

.currencies-detais #datetimepicker2 {
  width: 128px;
  margin-left: -23px;
}

.currencies-detais #datetimepicker4 .input-group-text {
  background-color: #fff !important;
  border-left: 1px solid #fff !important;
}

.currencies-detais #datetimepicker2 .input-group-text {
  background-color: #fff !important;
  border-left: 1px solid #fff !important;
}

.currencies-detais #datetimepicker2 input {
  font-size: 12px;
  border-right: 0px;
}

.currencies-detailsss .currency-summary h1 {
  font-weight: 600;
  font-size: 20px;
  color: #18364a;
  margin-bottom: 20px;
  margin-top: 32px;
}

.currencies-detailsss .currency-summary-back {
  height: 316px;
  background: #f2f2f2;
}

.currencies-detailsss .currency-summary {
  margin-top: 15%;
}

.currencies-detailsss .currency-button {
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  text-align: center;
  text-transform: uppercase;
  color: #149ec3;
  border: 2px solid #149ec3;
  box-sizing: border-box;
  border-radius: 40px;
  padding: 8px 30px;
}

.currencies-detais .select-input-contact1 {
  font-weight: 400;
  font-size: 12px;
  color: #18364a;
  border: 1px solid #ced4da;
  width: 90px;
  height: 35px;
}

.currencies-break {
  padding-bottom: 12%;
}

.currencies-detais .currencies-day-desp ul {
  list-style: none;
  display: inline-block;
}

.currencies-detais .currencies-day-desp li {
  font-weight: 500;
  font-size: 12px;
  text-align: right;
  color: #18364a;
  padding: 8px;
  display: inline-block;
}

.currencies-detais .currencies-day-desp .active {
  background: #b8e2ed;
  border-radius: 4px;
}

.currencies-detais .currencies-day-desp li:hover {
  background: #b8e2ed;
  border-radius: 4px;
}

.currencies-detais .currencies-day-desp {
  margin-top: 30px;
  /* margin-left: 57px; */
  margin-bottom: 2px;
}

.currencies-detais .select-input-contact2 {
  font-weight: 400;
  font-size: 12px;
  color: #18364a;
  border: 1px solid #ced4da;
  width: 127px;
  height: 35px;
}

.menu .navbar-logo {
  height: 50px;
}

.modal-dialog .modal-heading {
  display: flex;
  width: 100%;
  padding-left: 10px;
}

.modal-dialog .modal-heading .left-logo {
  width: 20%;
}

.modal-dialog .modal-heading .center-part {
  width: 70%;
  text-align: center;
}

.modal-dialog .modal-heading .right-part {
  width: 10%;
  padding-right: 10px;
}

.event-crypto-heading {
  font-size: 19px !important;
  font-weight: 600;
}

.event-crypto-line {
  background-color: #007bff;
  height: 4px;
  width: 70px;
  /* margin-left: -35px; */
}

.video-page-title {
  margin-top: -2px;
}

/* index page */
.secure-wallet {
  position: relative;
  height: 100px;
}

.secure-wallet img {
  margin-top: 15px;
  height: 90px;
  width: 100%;
}

.secure-wallet .secure-btn {
  width: 140px;
  height: 35px;
  border-radius: 40px;
  background-color: #149ec3;
  color: #fff;
  border: transparent;
  position: absolute;
  right: 40px;
  margin-top: 0px;
  -webkit-margin-before: 40px;
  font-weight: bold;
  font-size: 12px;
  top: 2px;
}

.secure-wallet h6 {
  position: absolute;
  right: 30%;
  margin-top: -50px;
  color: #fff;
}

.secure-wallet .secure-blue {
  color: #149ec3;
}

#ulCurrencyTicker .redles {
  padding-left: 5px;
}

.navbar-nav .join-us {
  margin-top: 10px;
}

.navbar-nav .join-us a {
  width: 115px;
  height: 34px;
}

.menu_icon .img1 {
  width: 12px;
  height: 2px;
}

.search-icon {
  margin-top: -28px;
}

.search-icon .search-img {
  cursor: pointer;
}

.search-div {
  position: absolute;
  left: -360px;
  height: 400px;
  width: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: -20px;
}

.search-div .fa-search {
  margin-left: -100px;
}

.press-margin-relese {
  margin-top: 10px;
}

.left-111 {
  left: -100px;
}

.w-24 {
  width: 24px;
}

.banner-textover .captcha img {
  height: 50px;
  width: 100px;
}

#content-wrapper .bg-image-full {
  margin-top: -10px;
}

.eadet-border {
  border-right: 0px solid #e0e0e0;
}

.border-b {
  border-bottom: 0px;
}

.people-carousel {
  margin-top: 150px;
}

#currency-paf h3 {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin-top: 0%;
  color: #ffffff;
  margin-bottom: 19px;
}

#currency-paf p {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin: 0 12%;
  margin-bottom: 20px;
  color: #ffffff;
}

#currency-paf .sub-now-btn {
  font-weight: 600;
  font-size: 12px;
  background: #149ec3;
  border-radius: 40px;
  text-decoration: none !important;
  color: #ffffff !important;
  padding: 10px 35px;
  text-transform: uppercase;
  margin-top: 5%;
}

#write_currency {
  margin: 21px auto;
}

#main_cur {
  margin-top: 52px;
}

#partner p {
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  color: #ffffff;
  margin: 0 6%;
  margin-bottom: 15px;
}

#partner h5 {
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  margin-top: 20px;
  margin-left: 0%;
  color: #ffffff;
}

#partner .banner-content .banner-textover {
  grid-area: overflow;
  margin-top: -3%;
  text-align: center;
}

#partner .banner-content .main_image_banner {
  margin-top: 9px;
  grid-area: overflow;
}

#partner .view-submitrequest {
  font-weight: 600;
  font-size: 12px;
  align-items: center;
  text-align: center;
  color: #fff;
  border-radius: 40px;
  margin-bottom: 20px;
  padding: 7px 25px;
  /* margin-left: -44px; */
  text-transform: uppercase;
  background: #1bb4dd;
}

.partnrs-details {
  background: #f9f9f9;
  padding: 30px 12%;
}

.partnrs-details h1 {
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  color: #050b37;
}

.partnrs-details p {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #505762;
}

.partner-space {
  margin-top: 32px;
  margin-bottom: 60px;
}

.partner-space .partnrs-summry h5 {
  font-weight: 600;
  font-size: 16px;
  color: #18364a;
}

.partner-space .partnrs-summry ul {
  list-style-type: none;
  margin-left: -41px;
}

.partner-space .partnrs-summry li {
  font-weight: 400;
  font-size: 14px;
  color: #3f3f3f;
}

.partner-space .partnrs-summry p {
  font-weight: 400;
  font-size: 14px;
  color: #3f3f3f;
}

.partner-space .partnrs-summry .orange-line {
  border: 2px solid #f8921f;
  width: 50px;
  margin-bottom: 15px;
}

.partner-space .partnrs-details-img {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}

.partner-space .partnrs-details-img .img-shadow1 {
  position: absolute;
  top: 80px;
  left: 20px;
  z-index: -1;
}

.partner-submit .view-submitrequest {
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  border-radius: 40px;
  margin-bottom: 20px;
  padding: 7px 25px;
  text-transform: uppercase;
  background: #1bb4dd;
}

.partner-submit {
  text-align: center;
  margin-top: 22px;
  margin-bottom: 58px;
}

.partner-space .partnrs-summry1 h5 {
  font-weight: 600;
  font-size: 16px;
  color: #18364a;
  margin-left: 20px;
}

.partner-space .partnrs-summry1 ul {
  list-style-type: none;
  margin-left: -20px;
}

.partner-space .partnrs-summry1 li {
  font-weight: 400;
  font-size: 14px;
  color: #3f3f3f;
}

.partner-space .partnrs-summry1 p {
  font-weight: 400;
  font-size: 14px;
  color: #3f3f3f;
  margin-left: 20px;
}

.partner-space .partnrs-summry1 .orange-line {
  border: 2px solid #f8921f;
  width: 50px;
  margin-bottom: 15px;
  margin-left: 20px;
}

.margin-partner {
  margin-top: 12px;
  margin-bottom: 12px;
}

.margin-partner1 {
  margin-top: 12px;
  margin-bottom: 58px;
}

.user-info-book {
  font-size: 16px;
  color: #18364a;
  font-weight: 800;
  text-align: right;
}

.bookmark-people .checkedstar {
  color: #ffc107;
  width: 22px;
  font-size: 22px;
}

.bookmark-people #white {
  color: #828282;
  width: 22px;
  font-size: 22px;
}

.bookmark-people .user-social1 {
  font-size: 10px;
  color: #505762;
  font-weight: 800;
  text-align: left;
}

.bookmark-people .user-social1 .fa-facebook {
  background: #3b5998;
  color: white;
}

.bookmark-people .user-social1 .fa-twitter {
  background: #55acee;
  color: white;
}

.bookmark-people .user-social1 .fa-linkedin {
  background: #0e76a8;
  color: white;
}

.bookmark-people .user-social1 .fa {
  padding: 6px;
  width: 22px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  margin-right: 4px;
  margin-left: 2px;
}

.bookmark-people .preess-right {
  font-weight: 400;
  font-size: 11px;
  color: #9b9b9b;
  margin-bottom: 27px;
  margin-left: 0;
}

.bookmark-people .preess-right .like_10 {
  margin-top: -2px;
  margin-right: 3px;
}

.bookmark-people .preess-right .span-span {
  margin-right: 12px;
}

.bookmark-summary h5 {
  font-weight: 600;
  font-size: 18px;
  color: #18364a;
}

.bookmark-summary .orange-line {
  border: 2px solid #f8921f;
  width: 50px;
  margin-bottom: 15px;
}

.bookmark-summary p {
  font-weight: 400;
  font-size: 12px;
  color: #9b9b9b;
  margin-top: -6px;
}

#book-video {
  font-weight: 600;
  font-size: 12px;
  color: #505762;
  margin-bottom: 12px;
  margin-left: 0;
}

#book-video .date-book {
  font-weight: 500;
  font-size: 12px;
  color: #828282;
}

#external-video1 .more-article-art1 .more-btn-art1,
#external-video .more-article-art1 .more-btn-art1 {
  font-weight: 600;
  font-size: 12px;
  text-align: right;
  text-decoration: underline !important;
  text-transform: uppercase;
  color: #18364a;
}

#external-video1 .more-article-art1 .fa-angle-right,
#external-video .more-article-art1 .fa-angle-right {
  color: #18364a;
  font-size: 15px;
  margin-left: -2px;
  border: 0;
  border-radius: 0;
}

#external-video1 .more-article-art1,
#external-video .more-article-art1 {
  text-align: right;
  margin-top: 3%;
}

.more-article-art2 {
  font-weight: 600;
  font-size: 12px;
  text-align: right;
  text-decoration: underline !important;
  text-transform: uppercase;
  color: #18364a;
}

.more-article-art2 .fa-angle-right {
  color: #18364a;
  font-size: 15px;
  margin-left: -2px;
  border: 0;
  border-radius: 0;
}

.more-article-art2 {
  text-align: right;
  margin-top: 3%;
}

#book-para {
  font-weight: 500;
  font-size: 14px;
  color: #18364a;
}

.book-col-4 .col-lg-4 {
  /* -ms-flex: 0 0 32.333333% !important; */
  /* flex: 0 0 40% !important; */
  max-width: 32.333333%;
}

.book-col-4 .col-lg-4 img {
  height: 125px;
  width: 103px;
}

.book-col-4 .border-book {
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

#newestid {
  width: 120px;
  height: 35px;
  margin-right: 13px;
  margin-bottom: 35px;
}

#newestid1 {
  height: 35px;
}

.special-comment {
  margin-left: 16px;
}

.special-comment1 {
  margin-left: 55px;
}

#external-video1 .span-special1 {
  font-weight: 600;
  font-size: 14px;
  color: #505762;
  margin-bottom: 0px;
  padding: 0px 5px;
}

#external-video1 .para-one-one {
  font-weight: 400;
  font-size: 14px;
  color: #505762;
  margin-top: -8px;
  padding: 7px 5px;
  border-bottom: 1px solid #e0e0e0;
}

.border-space-second {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.border-space-second1 {
  border: 1px solid #e0e0e0;
}

.span_bor .para_tym {
  font-weight: 500;
  font-size: 12px;
  color: #bdbdbd;
  padding: 0px 5px;
}

.span_bor p {
  font-weight: 500;
  font-size: 12px;
  color: #828282;
  padding: 0px 5px;
}

.specification_date {
  font-weight: 600;
  font-size: 12px;
  color: #828282;
  margin-left: 5px;
}

.span_bor {
  border-bottom: 1px solid #e0e0e0;
}

.index-graph-border {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 18px;
}

.index-overview select {
  font-size: 12px;
  width: 131px;
}

#select-overview {
  font-size: 12px;
  width: 230px;
}

.index-graph-border {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 18px;
}

.graph-data {
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.index-graph {
  margin-left: -28px;
}

.index-graph1 {
  margin-left: 0px;
}

.index-graph2 {
  margin-left: -23px;
}

.index-graph3 {
  margin-left: -23px;
}

.index-graph3 a {
  font-weight: 600;
  font-size: 12px;
  color: #18364a;
}

.index-composition {
  margin-top: 35px;
}

.index-composition h1 {
  font-weight: 600;
  font-size: 16px;
  color: #050b37;
}

.index-graph4 {
  margin-top: 35px;
}

.arrow-div-people-graph {
  margin-top: -3%;
  z-index: 1;
  position: absolute;
  right: 9%;
}

#chartContainer {
  height: 400px;
  width: 100%;
}

.index-graph4 .second-col {
  width: 130px;
  min-width: 130px;
  max-width: 130px;
}

.index-graph4 .first-col {
  width: 125px;
  min-width: 125px;
  max-width: 125px;
}

.index-graph4 .third-col {
  width: 160px;
}

.index-graph4 .sixth-col {
  width: 142px;
}

.index-graph4 .fourth-col {
  width: 132px;
}

.index-graph4 .fivth-col {
  width: 140px;
}

.index-graph4 .last-col {
  width: 140px;
  min-width: 140px;
  max-width: 140px;
}

.index-graph4 .table td,
.table th {
  padding: 0.15rem 0.65rem !important;
  vertical-align: middle !important;
  border-top: 1px solid #dee2e6;
}

.index-graph4 .currencies-crypto h5 {
  font-size: 13px;
  color: #18364a;
  font-weight: 400;
  text-align: center;
}

.index-graph4 .currencies-crypto .third-col h6 {
  font-size: 13px;
  color: #18364a;
  font-weight: 400;
  text-align: center;
}

.index-graph4 .currency-circle {
  margin-left: 0px;
  margin-right: 4px;
}

.index-graph4 .currencies-green {
  font-weight: 400;
  font-size: 14px;
  color: #27ae60;
  text-align: center;
}

.index-graph4 .currencies-red {
  font-weight: 400;
  font-size: 14px;
  color: #eb5757;
  text-align: center;
}

.index-graph4 .dropdown-prss {
  border: 0.75px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
}

.menu {
  display: none;
}

#demo .btn-read-more {
  background-color: #149ec3;
  height: 30px;
  border-radius: 18px;
  color: #fff;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}

#crytoratingbanner {
  background: url(../src/assets/images/rating-banner.png) no-repeat;
  background-size: contain;
  float: left;
  height: auto;
  margin-bottom: 0px;
}

#crytoratingbanner h2 {
  color: #ffffff;
  font-size: 44px;
  font-weight: 600;
  text-align: center;
}

#crytoratingbanner .tab-content {
  clear: both;
  margin-top: 16%;
  background: #fff;
  display: block;
  padding-top: 20px;
}

#crytoratingbanner1 ul {
  display: inline-block;
  border-radius: 20px;
  border: solid 1.5px #3f3f3f;
  background-color: #ffffff;
  margin-top: 58px;
}

#crytoratingbanner1 .nav-pills li {
  width: 180px;
}

#crytoratingbanner .banner-pad {
  padding: 55px 150px;
  margin-bottom: 21px;
}

#crytostar {
  text-align: right;
  position: relative;
  left: -21px;
  margin-top: 14px;
  color: #2f343a;
}

#crytostar img {
  margin-top: -5px;
  margin-left: 9px;
}

#crytoratingbanner1 .nav-pills li {
  width: 180px;
}

#pills-tabContent .people {
  margin-top: -18%;
  background: #fff;
  padding-top: 22px;
}

#crytoratingbanner1 ul {
  display: inline-block;
  border-radius: 20px;
  border: solid 1.5px #3f3f3f;
  background-color: #ffffff;
  margin-top: 58px;
}

#crytoratingbanner .people .people-border {
  text-align: left;
}

#crytoratingbanner .people .people-info h6 {
  font-weight: 600;
  font-size: 13px;
  color: #2f343a;
  text-align: center;
  margin-top: 12px;
}

#crytoratingbanner .people .people-info .peopel-img {
  margin-top: 19px !important;
  margin-left: 9px !important;
}

#crytoratingbanner .people .people-space {
  margin-right: 10px !important;
  margin-right: 10px !important;
}

#crytoratingbanner2 {
  text-align: center;
  margin-top: 0px;
  background: #fff;
  padding-bottom: 49px;
}

#crytostar1 {
  text-align: center;
  position: relative;
  left: 11px;
  margin-top: 14px;
  margin-bottom: 0px;
  color: #2f343a;
}

#crytoratingbanner2 .people-info h6 {
  font-weight: 600;
  font-size: 14px;
  color: #2f343a;
  text-align: center;
  margin-bottom: 21px;
}

#crytoratingbanner2 .peopel-img {
  margin-top: 23px;
  margin-left: 0px;
}

#crytoratingbanner2 .people-border {
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 10%);
  border-radius: 6px;
  width: 200px;
  border-bottom: 4px #149ec2 solid;
}

.image-rating {
  margin-bottom: 15px;
  margin-top: 2px;
  margin-right: 0px !important;
}

.load-people-more56 {
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #18364a;
}

.load-load56 {
  margin-top: 0px;
  background: #fff;
  padding-bottom: 25px;
}

#disclaimer {
  height: auto;
  background: #f2f2f2;
  clear: both;
  width: auto;
}

#crytoratingbanner2 .people .people-space {
  margin-right: 0px !important;
}

#crytoratingbanner2 .col-lg-3 {
  -ms-flex: 0 0 23%;
  flex: 0 0 23%;
  max-width: 23%;
  text-align: center !important;
}

#peopleinfo56 .people-space56 {
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 10%);
  border-radius: 6px;
  width: 200px;
  border-bottom: 4px #149ec2 solid;
  margin-right: 10px !important;
  margin-top: 18px;
}

.load-people-more56 img {
  margin-right: 2px;
  margin-top: -2px;
}

/*currencies page*/
.currency-summary-back {
  background: #f2f2f2;
  height: 316px;
  width: 100%;
}

.currency-chart {
  width: 100%;
  height: 400px;
  text-align: center;
  background: #f2f2f2;
  color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.currency-button {
  border: 2px solid #149ec3;
  border-radius: 40px;
  font-family: Poppins;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  padding: 7px 25px;
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #149ec3;
  /* width: 100px; */
}

.grey-bg {
  background: #f8f8f8;
  height: auto;
  padding: 40px;
  text-align: center;
}

.event-body .top .grey-bg ul li {
  display: inline-block;
  width: 150px;
}

.bgimage {
  width: 97.5%;
  margin-left: 14px;
  height: 300px;
  // background: url("../img/AmericaBanner.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.currencies-detais .dropdown-currencies {
  margin-left: -23px;
}

.currencies-detais .currenecies-heading h1 {
  font-weight: 600;
  font-size: 30px;
  color: #050b37;
}

.currencies-detais .currenecies-heading p {
  font-weight: 400;
  margin-top: -1%;
  margin-left: 1px;
  opacity: 0.9;
  font-size: 12px;
  color: #828282;
}

.currencies-detais .currencies-search input {
  font-size: 12px;
  color: #9b9b9b;
}

.currencies-detais .currencies-search .fontuser1 i {
  position: absolute;
  right: 23px;
  top: 9px;
  color: #505762;
  font-size: 19px;
  font-weight: 400;
}

.currencies-detais .currenecies-price ul {
  list-style: none;
  margin-left: -51px;
  margin-top: 15px;
  display: inline-block;
}

.currencies-detais .currenecies-price li {
  display: inline-block;
  padding: 0px 34px;
  border-left: 1px dashed #e0e0e0;
}

.currencies-detais .currenecies-price li:first-child {
  border-left: 0px dashed #e0e0e0;
  margin-left: -21px;
}

.currencies-detais .currenecies-price li p {
  font-weight: 400;
  font-size: 14px;
  color: #4f4f4f;
  opacity: 0.9;
  color: #4f4f4f;
  opacity: 0.9;
}

#bitcondetail .currency-summary .editor-text {
  font-weight: 600;
  font-size: 18px;
  color: #050b37;
}

#bitcondetail .bitcoin-ppage {
  margin-top: 25px;
}

#bitcondetail .bicoin-border {
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  height: 2px;
}

#bitcondetail .bitcoin-ppage h6 {
  font-weight: 500;
  font-size: 16px;
  color: #828282;
  margin-left: 25px;
}

#bitcondetail .bitcoin-ppage h2 {
  font-weight: 600;
  font-size: 20px;
  color: #3f3f3f;
  margin-left: 25px;
}

#bitcondetail .bitcoin-ppage .dec-date {
  font-size: 14px;
  color: #828282;
  font-weight: 400;
}

#bitcondetail .bitcoin-ppage .red {
  font-weight: 600;
  font-size: 18px;
  color: #d63939;
}

#bitcondetail .bitcoin-ppage .green {
  font-weight: 600;
  font-size: 18px;
  color: #27ae60;
}

#bitcondetail .bitcoin-ppage .fa-caret-up {
  margin-right: 7px;
}

#bitcondetail .bitcoin-ppage .fa-caret-down {
  margin-right: 7px;
  margin-left: 7px;
}

#main_cur {
  margin-top: -65px;
}

.currencies-detais .currenecies-price li h1 {
  font-weight: 600;
  font-size: 27px;
  color: #18364a;
  font-size: 27px;
  color: #18364a;
}

.currencies-detais .currenecies-price li h4 {
  font-weight: 600;
  font-size: 22px;
  color: #18364a;
  color: #18364a;
}

.currencies-detais .currenecies-price li .red {
  color: #d63939 !important;
  font-size: 22px;
}

.currencies-detais .currenecies-price li .caret {
  margin-right: 6px;
}

.currencies-detais .currenecies-price h3 .green {
  font-size: 22px;
  color: #27ae60 !important;
}

.currencies-detais .currencies-share {
  margin-top: 22px;
}

.currencies-detais .currencies-share .dropdown-currencies .dropdown-menu {
  min-width: 1rem !important;
}

.currencies-detais .currencies-search input {
  height: 35px;
}

.currencies-detais .currencies-share .fullscreen a {
  font-weight: 600;
  font-size: 12px;
  color: #18364a;
  word-spacing: -2px;
  text-transform: uppercase;
}

.currencies-detais .currencies-share .dropdown-currencies {
  margin-left: 13px;
}

.currencies-detais .currencies-share .social-icon-share {
  margin-left: -3px;
}

.currencies-detais .currencies-share .fullscreen {
  text-align: right;
}

.currencies-detais .currency-chart {
  background: #f2f2f2;
  height: 400px;
}

.currencies-detais .currency-chart h1 {
  font-weight: 900;
  font-size: 40px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #e0e0e0;
  padding: 15% 0;
  text-align: center;
  border-bottom: 1px solid #f2f2f2;
}

.currencies-detais .currencies-depth {
  margin-left: 0px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.currencies-detais #datetimepicker4 input {
  font-size: 12px;
  border-right: 0px;
}

.currencies-detais #datetimepicker4 {
  width: 128px;
}

.currencies-detais #datetimepicker2 {
  width: 128px;
  margin-left: -23px;
}

.currencies-detais #datetimepicker4 .input-group-text {
  background-color: #fff !important;
  border-left: 1px solid #fff !important;
}

.currencies-detais #datetimepicker2 .input-group-text {
  background-color: #fff !important;
  border-left: 1px solid #fff !important;
}

.currencies-detais #datetimepicker2 input {
  font-size: 12px;
  border-right: 0px;
}

.currencies-detailsss .currency-summary h1 {
  font-weight: 600;
  font-size: 20px;
  color: #18364a;
  margin-bottom: 20px;
  margin-top: 32px;
}

.currencies-detailsss .currency-summary-back {
  height: 316px;
  background: #f2f2f2;
}

.currencies-detailsss .currency-summary {
  margin-top: 15%;
}

.currencies-detailsss .currency-button {
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  text-align: center;
  text-transform: uppercase;
  color: #149ec3;
  border: 2px solid #149ec3;
  box-sizing: border-box;
  border-radius: 40px;
  padding: 8px 30px;
}

.currencies-detais .select-input-contact1 {
  font-weight: 400;
  font-size: 12px;
  color: #18364a;
  border: 1px solid #ced4da;
  width: 90px;
  height: 35px;
}

.currencies-break {
  padding-bottom: 6% !important;
}

.currencies-detais .currencies-day-desp ul {
  list-style: none;
  display: inline-block;
}

.currencies-detais .currencies-day-desp li {
  font-weight: 500;
  font-size: 12px;
  text-align: right;
  color: #18364a;
  padding: 8px;
  display: inline-block;
}

.currencies-detais .currencies-day-desp .active {
  background: #b8e2ed;
  border-radius: 4px;
}

.currencies-detais .currencies-day-desp li:hover {
  background: #b8e2ed;
  border-radius: 4px;
}

.currencies-detais .currencies-day-desp {
  margin-top: 30px;
  /* margin-left: 57px; */
  margin-bottom: 2px;
}

.currencies-detais .select-input-contact2 {
  font-weight: 400;
  font-size: 12px;
  color: #18364a;
  border: 1px solid #ced4da;
  width: 127px;
  height: 35px;
}

#bitcondetail {
  margin-top: 41%;
}

#bitcondetail .currency-summary .editor-text {
  font-weight: 600;
  font-size: 18px;
  color: #050b37;
  margin-top: 20px;
}

#dropdownMenuLink2 {
  font-size: 12px;
  color: #505762;
  display: block;
  width: 168px !important;
  padding: 9px;
  border: 0.75px solid #bdbdbd;
  border-radius: 4px;
}

.news-laptop .main_image_banner img {
  width: 100%;
  height: 320px;
}

.search-field img {
  position: absolute;
  right: 28px !important;
  top: 8px;
  height: 15px;
  width: 15px;
}

#partner-name p {
  font-size: 14px;
  color: #fff;
  margin-right: 30px;
  margin-left: 30px;
}

#partner-name .enroll-button-partner a {
  font-size: 14px;
  color: #fff;
}

.bg-prof-image-full {
  // background: url(../img/profile-people.png) no-repeat;
  height: 200px;
}

.profile-people .profile-text h4 {
  font-weight: 600;
  font-size: 24px;
  color: #050b37;
}

.profile-people .profile-text h5 {
  font-weight: 500;
  font-size: 18px;
  color: #505762;
}

.profile-people .profile-text h6 {
  font-size: 16px;
  color: #9b9b9b;
  font-weight: 400;
}

#hot-store {
  text-align: center;
  margin-top: 65px;
}

.porfile-image {
  margin-top: -10%;
}

.fs-12 {
  font-size: 12px;
}

.border-bottom-1 {
  border-bottom: 1px solid #ccc;
}

/* Plan Summary */
.fs-14 {
  font-size: 14px;
}

.orange-bg {
  background: linear-gradient(
    180deg,
    #cec3b3 26.07%,
    rgba(199, 153, 85, 0) 117.86%
  );
  width: 100%;
  padding: 1rem;
}

.fs-35 {
  color: #050b37;
  font-size: 35px;
}

.bg-white {
  width: 42%;
  margin: 15px auto 0;
  border-radius: 70px;
}

.time-bold {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 0;
}

.fs-12 {
  font-size: 12px;
}

.bg-active {
  background-color: #f8f8f8;
}

.bottom-border {
  border-bottom: 1px solid #eee;
}

.fs-6 {
  font-size: 13px !important;
  font-weight: 800;
}

.following {
  font-size: 12px;
  border: 1px solid #149ec3;
  border-radius: 15px;
  padding: 1px 10px;
}

.fs-15 {
  font-size: 15px;
}

.shadow-box {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 40%);
  border-radius: 4px;
  padding: 20px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0.5em 0em !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: none !important;
  background-color: none !important;
  border: none !important;
}

table.dataTable thead th,
table.dataTable thead td {
  border-bottom: 1px solid #e0e0e0 !important;
  font-weight: 600;
}

.ccd {
  margin-top: 40px;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
  color: #050b37;
}

.ccd p {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #18364a;
  margin: 0 17%;
  margin-bottom: 27px;
}

.indexcons td {
  font-size: 14px !important;
  color: #18364a !important;
  font-weight: 500 !important;
}

.table.dataTable.no-footer {
  border: 1px solid #ddd !important;
}

.table.dataTable thead th,
table.dataTable thead td {
  border-bottom: none !important;
}

.table.dataTable thead th,
table.dataTable tfoot th {
  color: #828282 !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  padding: 1.375rem 1.75rem 1.375rem 1.75rem !important;
}

.dataTables_length .form-control {
  display: initial !important;
  height: 35px !important;
}

.dataTables_length {
  margin-top: 20px;
}

.dataTables_filter label {
  display: inline-flex !important;
  align-items: center;
}

.dataTables_filter {
  margin-top: 20px;
}

//for captcha in captcha-component
.customCaptcha {
  box-shadow: none !important;
}

.ccp-footer .ccap-footer-middle {
  margin: 1rem 3rem;
}

#disclaimer {
  margin: 1rem 3rem;
}

.orange-line {
  border: 4px solid #f8921f;
  width: 50px;
}

/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

@import "primeicons/primeicons.css";
@import "~primeflex/primeflex.css";

/* custom style starts here */
.basic-spinner {
  margin-top: 100px;
  height: 100px;
  width: 100px;
}

.article-header-userdetails {
  align-items: center;
}

.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
  max-width: fit-content;
  display: grid;
}

.margin-auto {
  margin: auto;
}

.page-name-disabled {
  font-size: 2px;
}

@media only screen and (max-width: 720px) {
  #disclaimer .disclaimer-content1 {
    padding: 20px 10px;
  }

  #captcahCanvas {
    width: -webkit-fill-available !important;
    width: -moz-available !important;
  }

  .captcha-container {
    width: auto !important;
  }

  .ng-pristine {
    width: -webkit-fill-available;
    width: -moz-available;
  }
}

.select-publisher-dropdown {
  .p-dropdown {
    height: 50px;
  }
}
.p-calender-dropdown {
  .p-calendar {
    height: 50px;
  }
}

@media only screen and (max-width: 720px) {
  #disclaimer {
    margin: 1rem;
  }
  .ccp-footer .ccap-footer-middle {
    margin: 1rem;
  }
  .select-publisher-dropdown {
    .p-dropdown {
      width: -webkit-fill-available;
      width: -moz-available;
    }
  }
  .p-calender-dropdown {
    .p-calendar {
      width: -webkit-fill-available;
      width: -moz-available;
    }
  }
}

.cookies-popup {
  position: fixed;
  bottom: 0px;
  left: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: none;
}

.accept-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
}

//breadcrumb hidden below 992px
@media only screen and (max-width: 992px) {
  .bread-crumb {
    display: none;
  }
}

@media only screen and (min-width: 1440px) {
  .ccp-footer .ccap-footer-middle {
    margin: 1rem 5rem;
  }

  #disclaimer {
    margin: 1rem 5rem;
  }
}
